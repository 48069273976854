import { FormHelperText, InputLabel, SwitchProps } from '@mui/material';
import React from 'react';
import { useField } from 'formik';
import Switch from '../Switch';
import useI18nError from 'new/i18n/useI18nError';

type Props = SwitchProps & {
  name: string;
  label?: string;
  statusLabels?: [string, string];
  isInline?: boolean;
};

const FormSwitch: React.FC<Props> = ({
  name,
  label = '',
  statusLabels,
  isInline,
  ...props
}) => {
  const [field, meta, helpers] = useField<boolean>(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = !!meta.error && meta.touched;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
    props.onChange?.(event, event.target.checked);
  };

  return (
    <>
      <InputLabel sx={{ textAlign: 'left', color: '#000' }} htmlFor={name}>
        {label}
      </InputLabel>
      <Switch
        {...props}
        {...field}
        checked={field.value}
        onChange={handleChange}
      />
      {statusLabels && (
        <InputLabel
          sx={{
            display: isInline ? 'inline' : undefined,
            textAlign: 'left',
            color: '#000',
            fontSize: '0.875rem',
            marginTop: '4px',
          }}
          htmlFor={name}
        >
          {statusLabels[field.value ? 0 : 1]}
        </InputLabel>
      )}
      <FormHelperText error={hasError}>
        {hasError && errorMessage}
      </FormHelperText>
    </>
  );
};

export default FormSwitch;
