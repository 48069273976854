export const pricingMap = {
  annually: {
    lite: 99,
    standard: 99,
    premium: 99,
  },
  monthly: {
    lite: 99,
    standard: 99,
    premium: 99,
  },
};

export const DEBOUNCE_TYPING_TIMEOUT = 500;
