import { NamedRoute } from '../../routing/types'

export type PatientDetailsSettingsRouteName = 'PatientDetailsSettingsPage'

export const patientDetailsSettingsRoutes: NamedRoute[] = [
  {
    name: 'PatientDetailsSettingsPage',
    path: '/patient-details/settings'
  }
]
