import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import IconFontAwesome from 'new/components/IconFontAwesome';

type StyledBoxProps = {
  hasError?: boolean;
};

type Props = CheckboxProps & StyledBoxProps;

const CheckboxIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<StyledBoxProps>(({ hasError, theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  border: `1.5px solid ${
    hasError ? theme.palette.error.main : theme.palette.primary.main
  }`,
  marginRight: 5,
  backgroundColor: '#fff',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',

  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },

  'input:disabled ~ &': {
    background: theme.palette.grey[200],
    '&input:checked': {
      background: theme.palette.grey[400],
    },
    borderColor: theme.palette.grey[400],
  },
  'input:disabled:checked ~ &': {
    background: theme.palette.grey[400],
  },
}));

const CheckboxCheckedIcon = styled(CheckboxIcon)(({ theme, hasError }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  backgroundColor: hasError
    ? theme.palette.error.main
    : theme.palette.primary.main,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
}));

const Checkbox: React.FC<Props> = ({ hasError, ...props }) => (
  <MuiCheckbox
    {...props}
    checkedIcon={
      <CheckboxCheckedIcon hasError={hasError}>
        <IconFontAwesome name="faCheckSolid" size="sm" color="white" />
      </CheckboxCheckedIcon>
    }
    icon={<CheckboxIcon hasError={hasError} />}
    color="primary"
  />
);

export default Checkbox;
