/*
Page Header Component
================================================================================
For unauthenticated pages (i.e. the marketing site).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import { Link } from 'react-router';
import Cookies from 'universal-cookie';

// local
import styles from './styles.module.css';

const cookies = new Cookies();

const dashboardPages = [
  '/dentist/members',
  '/dentist/new-members',
  '/dentist/new-reviews',

  '/patient/payment-history',
  '/patient/membership-info',
  '/patient/profile',
  '/patient/your-dentist',
  '/patient/your-reviews',

  '/patient-details/dashboard',
  '/patient-details/payment-history',
  '/patient-details/settings',

  '/admin/dentists',
  '/admin/incomplete',
  '/admin/members',
  '/admin/reviews',
  '/admin/manage',
];

const onOfficeChange = (value) => {
  cookies.set('officeId', value, { path: '/' });
  console.log(cookies.get('officeId'));
  location.reload();
};

const onBackToAdmin = () => {
  localStorage.setItem('auth_token', cookies.get('beccascookie'));
  cookies.remove('memberId', { path: '/' });
  cookies.remove('beccascookie', { path: '/' });
  setTimeout(() => (window.location = '/admin/dentists'), 250);
};
/*
Page Header
================================================================================
*/
function PageHeader({ borderContent, children, pathname, title, user }) {
  if (title) {
    title = <h1 className="large-title">{title}</h1>;
  }

  if (user && user.type) {
    if (
      user.type === 'dentist' ||
      user.type === 'dentist_standard' ||
      user.type === 'office_admin'
    ) {
      borderContent = (
        <div styleName="border-content--user">
          <div className="pull-left">
            Welcome{' '}
            <span styleName="user-name">
              {user.firstName} {user.lastName}
            </span>
          </div>

          <div className="pull-right">
            <span
              styleName={
                'header-link' +
                (dashboardPages.indexOf(pathname) > -1
                  ? ' header-link--current'
                  : '')
              }
            >
              <Link to="/dentist/new-members">Dashboard</Link>
            </span>
            |
            <span
              styleName={
                'header-link' +
                (pathname === '/dentist/faq' ? ' header-link--current' : '')
              }
            >
              <Link to="/dentist/faq">FAQ</Link>
            </span>
            |
            <span
              styleName={
                'header-link' +
                (pathname === '/dentist/contact-admin'
                  ? ' header-link--current'
                  : '')
              }
            >
              <Link to="/dentist/contact-admin">Contact Admin</Link>
            </span>
            |
            <span styleName="header-link">
              <Link
                onClick={cookies.set('userId', user.id, { path: '/' })}
                to="/accounts/login"
              >
                Log Out
              </Link>
            </span>
          </div>
        </div>
      );
    } else if (user.type === 'client') {
      borderContent = (
        <div styleName="border-content--user">
          <div className="pull-left">
            Welcome{' '}
            <span styleName="user-name">
              {user.firstName} {user.lastName}
            </span>
          </div>

          <div className="pull-right">
            <span
              styleName={
                'header-link' +
                (dashboardPages.indexOf(pathname) > -1
                  ? ' header-link--current'
                  : '')
              }
            >
              <Link to="/dentist/new-members">Dashboard</Link>
            </span>
            |
            <span
              styleName={
                'header-link' +
                (pathname === '/dentist/faq' ? ' header-link--current' : '')
              }
            >
              <Link to="/dentist/faq">FAQ</Link>
            </span>
            |
            <span
              styleName={
                'header-link' +
                (pathname === '/dentist/contact-admin'
                  ? ' header-link--current'
                  : '')
              }
            >
              <Link to="/dentist/contact-admin">Contact Admin</Link>
            </span>
            |
            <span styleName="header-link">
              <Link
                onClick={cookies.set('userId', user.id, { path: '/' })}
                onClick={cookies.set('userId', user.id, { path: '/' })}
                to="/accounts/login"
              >
                Log Out
              </Link>
            </span>
          </div>
        </div>
      );
    } else if (user.type === 'client') {
      borderContent = (
        <div styleName="border-content--user">
          <div className="pull-left">
            Primary account holder:{' '}
            <span styleName="user-name">
              {user.firstName} {user.lastName}
            </span>
          </div>

          <div className="pull-right">
            {cookies.get('beccascookie') && (
              <span styleName="header-link" onClick={() => onBackToAdmin()}>
                Back to Admin
              </span>
            )}
            {/*    <span styleName={"header-link" + (dashboardPages.indexOf(pathname) > -1 ? " header-link--current" : "")}>
              <Link to="/patient/profile">Dashboard</Link>
            </span>
            }|
            <span styleName="header-link">
              <Link to="/faq">Help &amp; FAQ</Link>
            </span>
            |*/}
            <span styleName="header-link">
              <Link
                onClick={cookies.set('userId', user.id, { path: '/' })}
                to="/accounts/login"
              >
                Log Out
              </Link>
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div styleName="page-header">
        {(!user || !user.type) && (
          <div styleName="page-header__overlay">
            <div className="container">
              {title}
              {children}
            </div>
          </div>
        )}
      </div>

      <div styleName="border-content">
        <div className="container">{borderContent}</div>
      </div>
    </div>
  );
}

/*
Props
------------------------------------------------------------
*/
PageHeader.propTypes = {
  children: PropTypes.node,
  borderContent: PropTypes.node,
  pathname: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.oneOfType([
    PropTypes.bool, // will be `false` until loaded
    PropTypes.object,
  ]),
};

export default CSSModules(styles, { allowMultiple: true })(PageHeader);
