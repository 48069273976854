import React, { useEffect, DependencyList } from 'react';
import { useFormikContext, FormikContextType } from 'formik';

import { ValueWithCallback } from '../../types/functions';
import { FormikOnChangeHelperFunction } from '../../types/form';
import { getValueWithCallback } from '../../utils/functions';

type Props<T> = {
  onChange?: FormikOnChangeHelperFunction<T>;
  deps?: ValueWithCallback<DependencyList, FormikContextType<T>>;
  children?:
    | ((context: FormikContextType<T>) => React.ReactNode)
    | React.ReactNode;
};

const FormikOnChangeHelper = <T extends object>({
  children,
  deps,
  onChange,
}: Props<T>) => {
  const formikContext = useFormikContext<T>();

  useEffect(() => {
    onChange?.(formikContext);
  }, getValueWithCallback(formikContext, deps) || [formikContext]);

  return typeof children === 'function' ? children(formikContext) : null;
};

export { FormikOnChangeHelper };
