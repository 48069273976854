import React from 'react';
import { Grid } from '@mui/material';

const Footer: React.FC = ({ children }) => {
  return (
    <Grid container sx={{ width: '100%', justifyContent: 'center' }}>
      {children}
    </Grid>
  );
};

export default Footer;
