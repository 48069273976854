import React, { ReactText, useRef } from 'react';
import { useField } from 'formik';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  InputLabel,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Search from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import useFieldAutofocus from 'new/hooks/useFieldAutofocus';
import IconFontAwesome from 'new/components/IconFontAwesome';
import useI18nError from 'new/i18n/useI18nError';

export type InputFieldProps = TextFieldProps & {
  name: string;
  fixedLabel?: React.ReactNode;
  isLoading?: boolean;
  isSearchInput?: boolean;
  tooltip?: string;
  isStandardMulti?: boolean;
};

type StyledTextFieldProps = {
  multiline?: boolean;
  label?: React.ReactNode;
  variant?: string;
};

const StyledWrapper = styled('span', {
  shouldForwardProp: (prop) =>
    prop !== 'multiline' && prop !== 'label' && prop !== 'variant',
})<StyledTextFieldProps>(({ multiline, label, theme, variant }) => {
  const textareaStyles = multiline
    ? {
        paddingTop: 4,
        paddingBottom: 10,
        marginTop: label ? 18 : undefined,
      }
    : {};

  const labelStyles =
    multiline && label
      ? {
          '&.MuiInputLabel-shrink': {
            left: -12,
          },
        }
      : {};

  return {
    position: 'relative',

    '.MuiOutlinedInput-root': textareaStyles,

    '.MuiFormLabel-root': labelStyles,
    textarea: {
      position: 'relative',
      zIndex: 1,
    },

    fieldset: {
      backgroundColor: multiline ? theme.palette.grey[100] : 'auto',
      borderColor: multiline ? theme.palette.grey[300] : 'auto',
      legend: {
        display:
          (multiline && !label) || variant === 'outlined' ? 'none' : 'block',
      },
    },

    '.labelTooltip': {
      position: 'absolute',
      top: 8,
      right: -20,
      zIndex: 2,
    },
  };
});

function Input({
  name,
  fixedLabel,
  isLoading,
  isSearchInput,
  autoFocus,
  tooltip,
  isStandardMulti,
  ...props
}: InputFieldProps) {
  const inputRef = useRef<HTMLInputElement>();
  const [field, meta] = useField<ReactText>(name);
  const hasError = !!meta.error && meta.touched;
  const disabled = isLoading || props.disabled;
  const errorMessage = useI18nError(meta.error);
  useFieldAutofocus(inputRef, autoFocus);

  const getEndAdornment = () => {
    if (isLoading) {
      return (
        <InputAdornment position="end">
          <CircularProgress color="secondary" size={15} />
        </InputAdornment>
      );
    }
    if (isSearchInput) {
      return (
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      );
    }
    return props.InputProps?.endAdornment;
  };

  let variant = props.variant || 'standard';

  if (props.multiline) {
    variant = 'outlined';
  }

  return (
    <StyledWrapper
      multiline={props.multiline}
      label={props.label}
      variant={variant}
    >
      {fixedLabel && <InputLabel>{fixedLabel}</InputLabel>}
      {tooltip && (
        <Tooltip placement="top" title={tooltip}>
          <div className="labelTooltip">
            <IconFontAwesome name="faQuestion" color="grey400" isTooltip />
          </div>
        </Tooltip>
      )}
      <TextField
        {...props}
        {...field}
        id={props.id || field.name}
        onChange={(e) => {
          field.onChange(e);
          props.onChange?.(e);
        }}
        onBlur={(e) => {
          field.onBlur(e);
          props.onBlur?.(e);
        }}
        error={hasError}
        helperText={(hasError && errorMessage) || props.helperText}
        variant={isStandardMulti ? 'standard' : variant}
        InputProps={{
          endAdornment: getEndAdornment(),
          ...props.InputProps,
        }}
        disabled={disabled}
        inputRef={props.inputRef || inputRef}
      />
    </StyledWrapper>
  );
}

export { Input };
