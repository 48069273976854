import React, { useCallback, useRef, useState } from 'react';
import { useField } from 'formik';
import {
  InputAdornment,
  IconButton,
  TextFieldProps,
  Tooltip,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useFieldAutofocus from 'new/hooks/useFieldAutofocus';
import useI18nError from 'new/i18n/useI18nError';

type Props = TextFieldProps & {
  name: string;
  initialIsVisible?: boolean;
  customToggleLabel?: string;
  hideToggle?: boolean;
};

const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
  e.preventDefault();
};

function InputPassword<T>({
  name,
  autoFocus,
  initialIsVisible = false,
  customToggleLabel,
  hideToggle = false,
  variant = 'standard',
  ...props
}: Props) {
  const inputRef = useRef<HTMLInputElement>();
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const [field, meta] = useField<T>(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = meta.touched && !!meta.error;

  useFieldAutofocus(inputRef, autoFocus);

  const toggleVisibility = useCallback(
    () => setIsVisible((isVisible) => !isVisible),
    [],
  );

  return (
    <TextField
      id={props.id || field.name}
      {...props}
      {...field}
      type={isVisible ? 'text' : 'password'}
      error={hasError}
      helperText={hasError && errorMessage}
      variant={variant}
      InputProps={
        !hideToggle
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={`${isVisible ? 'hide' : 'show'} ${
                      customToggleLabel || 'password'
                    }`}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleVisibility}
                      onMouseDown={handleMouseDownPassword}
                      tabIndex={-1}
                    >
                      {isVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }
          : undefined
      }
      inputRef={props.inputRef || inputRef}
    />
  );
}

export { InputPassword };
