import { USER_TYPES } from 'common/constants';
import { selectCurrentPath } from 'common/selectors/router.selector';

import {
  selectAuthState,
  selectAuthLoadingState,
  selectUserType,
} from 'containers/App/selectors';
import createReducer from '../reducers';

import Cookies from 'universal-cookie';
import { TENANT } from 'new/tenants';
const cookies = new Cookies();

/**
 * Inject an asynchronously loaded reducer
 */
function injectAsyncReducer(store) {
  return (name, asyncReducer) => {
    store.asyncReducers[name] = asyncReducer; // eslint-disable-line
    store.replaceReducer(createReducer(store.asyncReducers));
  };
}

/**
 * Inject an asynchronously loaded saga
 */
function injectAsyncSagas(store) {
  return (sagas) => sagas.map(store.runSaga);
}

function redirectToLogin(store) {
  return (nextState, replace) => {
    const isLoggedIn = selectAuthState(store.getState());
    // If user is not logged in, redirect to '/accounts/logout'
    if (!isLoggedIn) {
      replace({
        pathname: '/accounts/login',
        state: { nextPathname: nextState.location.pathname },
      });
      return;
    }
  };
}

function redirectToMembersNew(store) {
  return (nextState, replace) => {
    const isLoggedIn = selectAuthState(store.getState());

    if (isLoggedIn) {
      replace({
        pathname: '/dentist-new/members',
        state: { nextPathname: nextState.location.pathname },
      });
      return;
    }
  };
}

function redirectToDashboard(store) {
  return (nextState, replace) => {
    const isLoggedIn = selectAuthState(store.getState());
    const userType = selectUserType(store.getState());
    if (isLoggedIn && userType === USER_TYPES.CLIENT) {
      replace('/patient-details/dashboard');
    } else if (isLoggedIn && userType === USER_TYPES.ADMIN) {
      replace('/admin/dentists');
    } else if (
      isLoggedIn &&
      (userType === USER_TYPES.DENTIST ||
        userType === USER_TYPES.DENTIST_STANDARD ||
        userType === USER_TYPES.OFFICE_ADMIN)
    ) {
      if (
        userType === USER_TYPES.DENTIST &&
        cookies.get('officeId') == undefined
      )
        cookies.set('officeId', '', { path: '/' });
      replace(TENANT.constants.redirectToDashboardPath);
    } else if (userType === USER_TYPES.SM_BUSINESS) {
      replace('/business/members');
    }
  };
}

function redirectTo404(store) {
  return (nextState, replace) => {
    replace('/error/404-not-found');
  };
}

function redirectToHome(store) {
  return (nextState, replace) => {
    replace('/');
  };
}

function redirectToSignup(store) {
  return (nextState, replace) => {
    replace(nextState.location.pathname.replace('//', '/'));
  };
}

function logoutReducer(store) {
  return (nextState, replace) => {
    store.replaceReducer(createReducer({}));
  };
}

/**
 * Helper for creating injectors
 */
export default function getHooks(store) {
  return {
    injectReducer: injectAsyncReducer(store),
    injectSagas: injectAsyncSagas(store),
    redirectToLogin: redirectToLogin(store),
    redirectToMembersNew: redirectToMembersNew(store),
    redirectToDashboard: redirectToDashboard(store),
    redirectTo404: redirectTo404(store),
    redirectToHome: redirectToHome(store),
    redirectToSignup: redirectToSignup(store),
    logoutReducer: logoutReducer(store),
  };
}
