import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { FormControl, FormLabel, Grid, TextField } from '@mui/material';
import { InputFieldProps } from './Input';
import { createArrayOfLength } from 'new/utils/arrays';

type Props = InputFieldProps & {
  codeLength?: number;
};

type CodeInputFieldProps = {
  name: string;
  index: number;
  codeArrLength: number;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
};

const getCodeArr = (length: number) =>
  new Array(length).fill(undefined).map((_, index) => index);

const getFieldName = (name: string, index: number) => `code.${index}`;

const CodeSubInput: React.FC<CodeInputFieldProps> = ({
  name,
  index,
  disabled,
  error,
  codeArrLength,
  autoFocus,
}) => {
  const fieldName = getFieldName(name, index);
  const [field, , helpers] = useField<string>(fieldName);
  const { setFieldValue } = useFormikContext<{ code: number[] }>();
  const inputRef = useRef<HTMLInputElement>(null);
  let focusTimeout: NodeJS.Timeout;

  const focusNextField = (nextIndex?: number) => {
    if (index < codeArrLength) {
      const element = document.getElementById(
        getFieldName(name, nextIndex || index + 1),
      ) as HTMLInputElement;
      element.focus();
      focusTimeout = setTimeout(function () {
        element.select();
      }, 1);
    }
  };

  const focusPrevField = () => {
    if (index > 0) {
      const element = document.getElementById(
        getFieldName(name, index - 1),
      ) as HTMLInputElement;
      element.focus();
      focusTimeout = setTimeout(function () {
        element.select();
      }, 1);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const fieldHasEmptyValue = !(event.target as HTMLInputElement).value;

    if (event.key === 'Backspace' && fieldHasEmptyValue) {
      focusPrevField();
    }
    if (event.key === 'ArrowLeft') {
      focusPrevField();
    }
    if (event.key === 'ArrowRight') {
      focusNextField();
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    const shouldChange = !value.length || value.replace(/[^0-9]/g, '');

    if (shouldChange) {
      helpers.setValue(value);

      if (value.length && index + 1 < codeArrLength) {
        focusNextField();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const pasteNumbers = paste?.replace(/[^0-9]/g, '');
    const codeArr = createArrayOfLength(
      codeArrLength,
      (index) => pasteNumbers[index],
    );

    setFieldValue(name, codeArr);
    focusNextField(codeArrLength - 1);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (autoFocus) {
      timeout = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }

    return () => {
      clearTimeout(timeout);
      clearTimeout(focusTimeout);
    };
  }, [autoFocus]);

  return (
    <Grid item>
      <TextField
        {...field}
        id={fieldName}
        inputRef={inputRef}
        inputProps={{
          maxLength: 1,
          style: {
            textAlign: 'center',
            width: 50,
            fontSize: '2rem',
            fontFamily: 'monospace',
          },
        }}
        variant="standard"
        disabled={disabled}
        onChange={handleChange}
        error={error}
        autoComplete="off"
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
      />
    </Grid>
  );
};

const ConfirmCodeInput: React.FC<Props> = ({
  name,
  label,
  isLoading,
  autoFocus,
  codeLength = 4,
  ...props
}) => {
  const disabled = isLoading || props.disabled;
  const codeArr = useMemo(() => getCodeArr(codeLength), [codeLength]);

  return (
    <FormControl component="div" sx={{ height: 70 }}>
      <FormLabel component="div">{label}</FormLabel>
      <Grid container spacing={2}>
        {codeArr.map((key, index) => (
          <CodeSubInput
            key={key}
            name={name}
            index={index}
            disabled={disabled}
            codeArrLength={codeArr.length}
            autoFocus={autoFocus && index === 0}
          />
        ))}
      </Grid>
    </FormControl>
  );
};

export default ConfirmCodeInput;
