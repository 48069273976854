import React, { ReactText } from 'react';
import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  RadioGroupProps,
  Box,
  Radio,
} from '@mui/material';
import MuiRadio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import { borderRadius } from 'new/constants/styles';
import useI18nError from 'new/i18n/useI18nError';

type Props = RadioGroupProps & {
  name: string;
  label?: string;
  options: RadioOption[];
  defaultValue?: ReactText;
};

type RadioOption = {
  value: ReactText;
  label: string;
  thumbnail: string;
};

type CustomLabelProps = RadioOption & {
  checked: boolean;
};

type StyledWrapperProps = {
  hasError?: boolean;
};

const StyledWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<StyledWrapperProps>(({ hasError, theme }) => ({
  position: 'relative',
  '& .MuiFormControl-root': {
    flexDirection: 'row',
    marginTop: 32,
    marginBottom: hasError ? 24 : 0,
  },
  '& .MuiFormGroup-root': {
    flexDirection: 'row',
  },
  '& .MuiFormLabel-root': {
    top: 10,
    marginRight: 80,
    marginBottom: 8,
  },
  '& .MuiFormControlLabel-root': {
    marginTop: 0,
    marginRight: theme.spacing(3),
    '& .MuiSvgIcon-root': {
      color: hasError && theme.palette.error.main,
    },
    '& .MuiTypography-root': {
      color: hasError && theme.palette.error.main,
      padding: `0 ${theme.spacing(3)} ${theme.spacing(3)}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius,
      border: '1px solid transparent',
    },
    '> .MuiRadio-root': {
      display: 'none',
    },
    '> .Mui-checked + .MuiFormControlLabel-label': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.grey[100],
    },
    '& .header-label': {
      paddingRight: 9,
      input: {
        zIndex: 0,
      },
    },
    '.thumbnail': {
      height: '7rem',
      width: '11rem',
      marginTop: theme.spacing(1),
      objectFit: 'cover',
      objectPosition: 'top',
      borderRadius,
      boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16)',
    },
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: 12,
    left: 11,
  },
}));

const CustomLabel = ({
  checked,
  value,
  thumbnail,
  label,
}: CustomLabelProps) => {
  return (
    <>
      <div className="header-label">
        <Radio checked={checked} name={`${value}`} />
        {label}
      </div>
      <img className="thumbnail" src={thumbnail} alt={`${value}`} />
    </>
  );
};

function RadioWithThumbnail({
  name,
  label,
  options = [],
  defaultValue,
  ...props
}: Props) {
  const [field, meta] = useField(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = meta.touched && !!meta.error;

  return (
    <StyledWrapper hasError={hasError}>
      <FormControl component="div" error={hasError}>
        <FormLabel component="div">{label}</FormLabel>
        <RadioGroup {...props} defaultValue={defaultValue} name={name}>
          {options.map(({ label, value, thumbnail }) => (
            <FormControlLabel
              key={value}
              {...field}
              control={<MuiRadio />}
              value={value}
              label={
                <CustomLabel
                  checked={value === field.value}
                  label={label}
                  thumbnail={thumbnail}
                  value={value}
                />
              }
              checked={value === field.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormHelperText error={hasError} id={`${name}-helper-text`}>
        {hasError && errorMessage}
      </FormHelperText>
    </StyledWrapper>
  );
}

export default RadioWithThumbnail;
