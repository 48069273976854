import { useIntl } from 'react-intl';
import { LocaleContext } from './LocaleContext';
import { useContext, useMemo } from 'react';

const useI18nError = (error?: string) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  return useMemo(() => {
    if (!error) return '';
    let errorId = error;
    const params: Record<string, string> = {};

    if (error.includes('validation.dateFormat.')) {
      errorId = 'validation.dateFormat';
      const format = error.split('.').pop();
      if (format) params.format = format;
    }

    if (error.includes('validation.mustBeExactly.')) {
      errorId = 'validation.mustBeExactly';
      const digits = error.split('.').pop();
      if (digits) params.digits = digits;
    }

    return error.includes('validation.')
      ? intl.formatMessage({ id: errorId }, params)
      : error;
  }, [locale, error]);
};

export default useI18nError;
