import {
  MembershipRouteName,
  membershipRoutes,
} from 'new/modules/MembershipPlan/routes';
import { EnrollRouteName, enrollRoutes } from 'new/modules/EnrollPage/routes';
import { CheckoutRouteName, checkoutRoutes } from 'new/modules/Checkout/routes';
import { SetupRouteNames, setupRoutes } from 'new/modules/Setup/routes';
import {
  TestPlaygroundRouteName,
  testPlaygroundRoutes,
} from 'new/modules/TestPlayground/routes';
import { LoginRouteName, loginRoutes } from 'new/modules/LoginPage/routes';
import { BrandingRouteName, brandingRoutes } from 'new/modules/Branding/routes';
import {
  BillingRouteName,
  billingRoutes,
} from 'new/modules/BillingPage/routes';
import { oldRoutes, OldRoutesName } from 'new/routing/oldRoutes';
import {
  AnalyticDashboardRouteName,
  analyticDashboardRoutes,
} from 'new/modules/AnalyticDashboardPage/routes';
import {
  MembersRoutesName,
  membersRoutes,
} from 'new/modules/MembersPage/routes';
import {
  MembershipPlansManageRoutesName,
  membershipPlansManageRoutes,
} from 'new/modules/MembershipPlansManage/routes';
import {
  OfficeSettingsRoutesName,
  officeSettingsRoutes,
} from 'new/modules/OfficeSettings/routes';
import {
  UserSettingsRouteName,
  userSettingsRoutes,
} from 'new/modules/UserSettings/routes';
import {
  BusinessConnectRouteName,
  businessConnectRoutes,
} from 'new/modules/BusinessConnect/routes';
import {
  CommunityConnectRouteName,
  communityConnectRoutes,
} from 'new/modules/CommunityConnect/routes';
import {
  MarketingSpaceRouteName,
  marketingSpaceRoutes,
} from 'new/modules/MarketingSpace/routes';
import {
  InternalPatientRouteName,
  internalPatientRoutes,
} from 'new/modules/InternalPatientSignup/routes';
import {
  ExternalPatientLandingRouteName,
  ExternalPatientRouteName,
  externalPatientRoutes,
} from 'new/modules/ExternalPatientSignup/routes';
import { ReportsRouteName, reportsRoutes } from 'new/modules/Reports/routes';
import {
  InhouseFinancingRouteName,
  inhouseFinancingRoutes,
} from 'new/modules/InhouseFinancing/routes';
import { DisputesRouteName, disputesRoutes } from 'new/modules/Disputes/routes';
import {
  upgradePlatformRoutes,
  UpgradePlatformRouteName,
} from 'containers/dashboardUpdate/UpgradeFormPage/routes';
import {
  multiLocationDashboardRoutes,
  MultiLocationDashboardRouteName,
} from 'new/modules/MultiLocationDashboard/routes';
import {
  ManageLocationsRouteName,
  manageLocationsRoutes,
} from 'new/modules/ManageLocations/routes';
import {
  ManageUsersRouteName,
  manageUsersRoutes,
} from 'new/modules/ManageUsers/routes';
import {
  ManagePlansRouteName,
  managePlansRoutes,
} from 'new/modules/ManagePlans/routes';
import {
  ManageGroupsRouteName,
  manageGroupsRoutes,
} from 'new/modules/ManageGroups/routes';
import {
  MultiLocationBillingRouteName,
  multiLocationBillingRoutes,
} from 'new/modules/MultiLocationBilling/routes';
import {
  MultiLocationReportsRouteName,
  multiLocationReportsRoutes,
} from 'new/modules/MultiLocationReports/routes';
import {
  OrderInfoCardsRouteName,
  orderInfoCardsRoutes,
} from 'new/modules/OrderInfoCards/routes';
import {
  PlanBuilderRoutesName,
  planBuilderRoutes,
} from 'new/modules/PlanBuilder/routes';
import {
  SeasonOfGivingRoutesName,
  seasonOfGivingRoutes,
} from 'new/modules/SeasonOfGiving/routes';
import {
  UpgradePlanRouteName,
  upgradePlanRoutes,
} from 'new/modules/UpgradePlan/routes';
import {
  LearnMoreRouteName,
  OfficeReferralRouteName,
  PatientReferralRouteName,
  referralRoutes,
} from 'new/modules/Referral/routes';
import {
  PatientDetailsDashboardRouteName,
  patientDetailsDashboardRoutes,
} from 'new/modules/PatientDetailsDashboard/routes';
import {
  PatientDetailsSettingsRouteName,
  patientDetailsSettingsRoutes,
} from 'new/modules/PatientDetailsSettings/routes';
import {
  PatientDetailsPaymentHistoryRouteName,
  patientDetailsPaymentHistoryRoutes,
} from 'new/modules/PatientDetailsPaymentHistory/routes';

export type RouteName =
  | MembershipRouteName
  | EnrollRouteName
  | CheckoutRouteName
  | SetupRouteNames
  | BrandingRouteName
  | BillingRouteName
  | TestPlaygroundRouteName
  | LoginRouteName
  | OldRoutesName
  | AnalyticDashboardRouteName
  | MembersRoutesName
  | MembershipPlansManageRoutesName
  | OfficeSettingsRoutesName
  | UserSettingsRouteName
  | BusinessConnectRouteName
  | CommunityConnectRouteName
  | MarketingSpaceRouteName
  | InternalPatientRouteName
  | ExternalPatientRouteName
  | ExternalPatientLandingRouteName
  | ReportsRouteName
  | InhouseFinancingRouteName
  | DisputesRouteName
  | UpgradePlatformRouteName
  | MultiLocationDashboardRouteName
  | ManageLocationsRouteName
  | ManageUsersRouteName
  | ManagePlansRouteName
  | ManageGroupsRouteName
  | MultiLocationBillingRouteName
  | MultiLocationReportsRouteName
  | OrderInfoCardsRouteName
  | PlanBuilderRoutesName
  | SeasonOfGivingRoutesName
  | UpgradePlanRouteName
  | PatientReferralRouteName
  | PatientDetailsDashboardRouteName
  | PatientDetailsSettingsRouteName
  | PatientDetailsPaymentHistoryRouteName
  | OfficeReferralRouteName
  | OfficeReferralRouteName
  | LearnMoreRouteName;

export const routes = [
  ...membershipRoutes,
  ...enrollRoutes,
  ...checkoutRoutes,
  ...setupRoutes,
  ...brandingRoutes,
  ...billingRoutes,
  ...testPlaygroundRoutes,
  ...loginRoutes,
  ...oldRoutes,
  ...analyticDashboardRoutes,
  ...membersRoutes,
  ...membershipPlansManageRoutes,
  ...officeSettingsRoutes,
  ...userSettingsRoutes,
  ...businessConnectRoutes,
  ...communityConnectRoutes,
  ...marketingSpaceRoutes,
  ...internalPatientRoutes,
  ...externalPatientRoutes,
  ...reportsRoutes,
  ...inhouseFinancingRoutes,
  ...disputesRoutes,
  ...upgradePlatformRoutes,
  ...multiLocationDashboardRoutes,
  ...multiLocationReportsRoutes,
  ...manageLocationsRoutes,
  ...manageUsersRoutes,
  ...managePlansRoutes,
  ...manageGroupsRoutes,
  ...multiLocationBillingRoutes,
  ...multiLocationReportsRoutes,
  ...orderInfoCardsRoutes,
  ...planBuilderRoutes,
  ...seasonOfGivingRoutes,
  ...upgradePlanRoutes,
  ...referralRoutes,
  ...patientDetailsDashboardRoutes,
  ...patientDetailsSettingsRoutes,
  ...patientDetailsPaymentHistoryRoutes,
];
