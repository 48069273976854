import {
  MenuItem,
  Autocomplete as MuiAutocomplete,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import { useField } from 'formik';
import { SelectOption } from 'new/components/Form/Select';
import useFieldAutofocus from 'new/hooks/useFieldAutofocus';
import useI18nError from 'new/i18n/useI18nError';
import React, { HTMLAttributes, useCallback, useRef } from 'react';

type AutocompleteRenderItem = (props: {
  props: HTMLAttributes<HTMLLIElement>;
  option: SelectOption;
  selected: boolean;
  menuItem: JSX.Element;
}) => JSX.Element;

type Props = TextFieldProps &
  Omit<
    AutocompleteProps<SelectOption, false, false, false>,
    'onChange' | 'onInputChange' | 'renderInput'
  > & {
    name: string;
    options: SelectOption[];
    label?: string;
    isLoading?: boolean;
    withSearchHighlight?: boolean;
    onInputChange?: (value?: string) => void;
    onChange?: (value: SelectOption | null) => void;
    autoFocus?: boolean;
    textFieldProps?: Omit<StandardTextFieldProps, 'onChange'>;
    renderItem?: AutocompleteRenderItem;
  };

const Autocomplete = ({
  name,
  label,
  options = [],
  isLoading,
  withSearchHighlight,
  autoFocus,
  onInputChange,
  onChange,
  textFieldProps,
  renderItem,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  const [inputValue, setInputValue] = React.useState('');
  const [{ value }, meta, helpers] = useField<SelectOption | null>(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = !!meta.error && meta.touched;

  const renderSearchHighlight = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: SelectOption,
      selected: boolean,
      renderItem?: AutocompleteRenderItem,
    ) => {
      const { key } = props as { key: '' };
      const lowerCasedValue = inputValue.toLowerCase();

      const indexOfFirst = key.toLowerCase().indexOf(lowerCasedValue) ?? -1;
      const indexOfLast =
        indexOfFirst >= 0 ? indexOfFirst + lowerCasedValue.length : -1;
      const stringBeforeMatch = key.substring(
        0,
        indexOfFirst > 0 ? indexOfFirst : indexOfFirst - 1,
      );
      const matchedString = key.substring(indexOfFirst, indexOfLast);
      const stringAfterMatch = key.substring(indexOfLast, key.length);

      const menuItem = (
        <>
          {stringBeforeMatch}
          <b>{matchedString}</b>
          {stringAfterMatch}
        </>
      );

      if (renderItem) {
        return renderItem({
          props,
          option,
          selected,
          menuItem,
        });
      } else {
        return (
          <MenuItem {...props} sx={{ whiteSpace: 'pre' }}>
            {menuItem}
          </MenuItem>
        );
      }
    },
    [inputValue],
  );

  useFieldAutofocus(inputRef, autoFocus);

  return (
    <MuiAutocomplete
      sx={{
        '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
          backgroundColor: '#e6f6fc',
        },
      }}
      id={props.id || name}
      {...props}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, value) => {
        setInputValue(value);
        onInputChange?.(value);
      }}
      onChange={(_, value) => {
        helpers.setValue(value);
        onChange?.(value);
      }}
      options={options}
      renderOption={(props, option, { selected }) =>
        withSearchHighlight
          ? renderSearchHighlight(props, option, selected, renderItem)
          : renderItem
          ? renderItem({
              props,
              option,
              selected,
              menuItem: <>{option.label}</>,
            })
          : undefined
      }
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          label={label}
          helperText={(hasError && errorMessage) || textFieldProps?.helperText}
          inputRef={inputRef}
          variant={textFieldProps?.variant || 'standard'}
        />
      )}
      disablePortal
    />
  );
};

export default Autocomplete;
