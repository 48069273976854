import { ValueWithCallback } from "../types/functions";
import { SelectOption } from "../components/Form/Select";
import { US_STATES } from "common/constants";

export const getValueWithCallback = <V, C>(
  callback: C,
  value?: ValueWithCallback<V, C>,
): V | undefined => {
  if (typeof value === 'function') {
    return (value as Function)(callback);
  }

  return value;
};

export const findOptionLabelByValue = (
  value: string,
  options: SelectOption[],
) => {
  const foundOption = options.find((option) => option.value === value);

  return foundOption?.label || value;
};

type Keys = keyof typeof US_STATES;
type Values = typeof US_STATES[Keys];
const states: { [key: string]: string } = US_STATES;

export const findStateKeyByValue = (value: Values) => {
  return Object.keys(US_STATES).find((a) => states[a] === value) ?? value;
};

export const timeoutPromise = (timeout: number) => new Promise((resolve) => {
  setTimeout(resolve, timeout, 'done');
});