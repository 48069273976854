import { NamedRoute } from '../../routing/types';

export type BrandingRouteName = 'BrandingPage';

export const brandingRoutes: NamedRoute[] = [
  {
    name: 'BrandingPage',
    path: '/account/branding',
    permission: '_all',
  },
];
