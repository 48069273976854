import React, { useContext } from 'react';
import { Form } from 'new/components/Form';
import useActiveOffice from 'new/hooks/useActiveOffice';
import { calculateAge } from 'new/utils/date';
import { useGetPlans } from 'new/modules/MembershipPlansManage/hooks';
import { Plan } from 'new/modules/MembershipPlansManage/types';
import { SelectOption } from 'new/components/Form/Select';
import { PlanType } from 'new/modules/InternalPatientSignup/types';
import { IntlShape, useIntl } from 'react-intl';
import { LocaleContext } from 'new/i18n/LocaleContext';

export const getIsMonthlyPlan = (type?: Plan['type']) =>
  ['month', 'custom'].includes(type || '');

export type PlansToFilterOutFromSelect = string[];

type Props = {
  name: string;
  isOpenByDefault?: boolean;
  label?: string;
  dobRestriction?: string;
  disabled?: boolean;
  plans?: Plan[];
  placeholder?: string;
  currentPlanId?: number | null;
  displayOnlyYearly?: boolean;
  displayOnlyExternal?: boolean;
  displayOnlyInternal?: boolean;
  displayNoBCC?: boolean;
  displayPrices?: boolean;
  variant?: 'standard' | 'filled';
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  size?: 'small';
  planType?: PlanType;
  plansToFilterOutFromSelect?: PlansToFilterOutFromSelect;
  newPlan?: boolean;
};

type GetFilteredOptionsProps = {
  memberships: Plan[];
  mobile?: boolean;
  intl: IntlShape;
  age?: number;
  currentPlanId?: number | null;
  displayPrices?: boolean;
  isSpanish?: boolean;
  displayOnlyExternal?: boolean;
  displayOnlyInternal?: boolean;
  planType?: PlanType;
  newPlan?: boolean;
};

const isPlanActive = (price: number, viewStatus?: string) => {
  return viewStatus === 'active' && price !== 0;
};

export const getFilteredOptions = ({
  memberships = [],
  mobile = false,
  intl,
  age,
  currentPlanId,
  displayPrices = false,
  isSpanish = false,
  displayOnlyExternal,
  displayOnlyInternal,
  planType,
  newPlan,
}: GetFilteredOptionsProps): [SelectOption[], Plan[]] => {
  let filteredMemberships = memberships;

  if (typeof age === 'number' && !isNaN(age)) {
    filteredMemberships = memberships.filter((membership) => {
      const {
        maxAge,
        minAge,
        type,
        subscription_age_group,
        id,
        yearly_membership,
        viewStatus,
        price,
      } = membership;

      const priceNumber = !isNaN(Number(price)) ? Number(price) : 0;
      const yearlyPriceNumber =
        yearly_membership && !isNaN(Number(yearly_membership[0].price))
          ? Number(yearly_membership[0].price)
          : 0;

      const showExternalYearly =
        (yearly_membership && yearly_membership[0].showOnEnrollment) ||
        newPlan == false;
      const showInternalYearly =
        (yearly_membership && yearly_membership[0].showOnInternalEnrollment) ||
        newPlan == false;

      // Filter out the plans that are not active or are deleted
      if (mobile == true) {
        if (
          !isPlanActive(priceNumber, viewStatus) &&
          !isPlanActive(
            yearlyPriceNumber,
            yearly_membership && yearly_membership[0].viewStatus,
          )
        ) {
          return false;
        }
      }

      if (currentPlanId === id) {
        return false;
      }

      if (
        displayOnlyExternal &&
        !membership.showOnEnrollment &&
        !showExternalYearly
      ) {
        return false;
      }
      if (
        displayOnlyInternal &&
        !membership.showOnInternalEnrollment &&
        !showInternalYearly
      ) {
        return false;
      }
      if (planType) {
        return (
          (membership.insured && planType === 'insured') ||
          (membership.uninsured && planType === 'uninsured')
        );
      }

      switch (type) {
        // If plan is "custom_year" or "custom", then trust the maxAge or minAge included in the plan
        case 'custom':
        case 'custom_year': {
          if (maxAge && age > maxAge) {
            return false;
          } else if (minAge && age < minAge) {
            return false;
          }
          return true;
        }
        // If plan is "year" or "month" and "adult"
        case 'month':
        case 'year': {
          if (subscription_age_group === 'adult') {
            const childPlan = memberships
              .filter(({ isDeleted }) => !isDeleted)
              .find(({ name }) =>
                name?.toLowerCase().includes('child wellness'),
              );
            if (maxAge && age > maxAge) {
              return false;
            } else if (minAge && age < minAge) {
              return false;
            } else if (maxAge && age <= maxAge) {
              return true;
            } else if (minAge && age > minAge) {
              return true;
            }
            // then use the maxAge from the child plan as the upper limit
            else if (childPlan?.maxAge) {
              return age > childPlan.maxAge;
            } else {
              // if no child plan, default to 0 as maxAge
              return age > 0;
            }
          } else {
            // if plan is "year" or "month" and "child", then use the maxAge to set upper limit
            return !(maxAge && age > maxAge);
          }
        }
      }

      return true;
    });
  }

  const options = filteredMemberships.map((membership) => {
    const isMonthly = getIsMonthlyPlan(membership.type);

    return {
      value: membership.id,
      label: `${
        isSpanish && membership.spanishName
          ? membership.spanishName
          : membership.name
      } (${
        displayPrices
          ? '$' +
            membership.price +
            '/' +
            intl.formatMessage({
              id: 'label.yr',
            })
          : intl
              .formatMessage({
                id: isMonthly ? 'label.monthly' : 'label.annually',
              })
              .toLowerCase()
      }) `,
    };
  });

  return [options, filteredMemberships];
};

export const getFlattedPlans = <T extends { yearly_membership?: T[] }>(
  plans: T[] = [],
): T[] =>
  plans.reduce((acc, plan) => {
    const yearPlan =
      plan.yearly_membership != undefined && plan.yearly_membership[0];

    if (yearPlan) {
      return [...acc, plan, yearPlan];
    }

    return [...acc, plan];
  }, [] as T[]);

const PlanSelect: React.FC<Props> = ({
  name,
  isOpenByDefault,
  label,
  dobRestriction,
  disabled,
  plans,
  placeholder,
  currentPlanId,
  displayOnlyYearly,
  displayOnlyExternal,
  displayOnlyInternal,
  variant,
  onClick,
  size,
  displayPrices,
  planType,
  plansToFilterOutFromSelect,
  newPlan = true,
}) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  label = label ?? intl.formatMessage({ id: 'label.selectPlan' });
  const isSpanish = locale === 'es-US';
  const { activeOfficeId } = useActiveOffice();
  const { data: allPlansResponse, isLoading } = useGetPlans(
    plans ? undefined : activeOfficeId,
  );
  const plansFlatted = getFlattedPlans(
    plans || allPlansResponse?.custom_memberships,
  )
    .filter(
      (plan) =>
        plan.viewStatus === 'active' &&
        Number(plan.price) != 0 &&
        (newPlan == false || plan.showOnInternalEnrollment === true),
    )
    .filter((plan) => {
      if (displayOnlyYearly) {
        return !getIsMonthlyPlan(plan.type);
      } else if (displayOnlyExternal) {
        return plan.showOnEnrollment;
      }
      return true;
    })
    .filter((plan) => {
      if (planType) {
        return (
          (planType === 'insured' && plan.insured) ||
          (planType === 'uninsured' && plan.uninsured)
        );
      }
      return true;
    });

  const age = dobRestriction?.length ? calculateAge(dobRestriction) : undefined;
  let [planOptions] = getFilteredOptions({
    memberships: plansFlatted,
    intl,
    age,
    currentPlanId,
    displayPrices,
    isSpanish,
    displayOnlyExternal,
    displayOnlyInternal,
    newPlan,
  });

  if (isNaN(age as number)) {
    planOptions = [];
  }

  const options = plansToFilterOutFromSelect
    ? planOptions.filter(
        ({ label }) =>
          label &&
          !plansToFilterOutFromSelect.find((planName) =>
            label.includes(planName),
          ),
      )
    : planOptions;

  return (
    <Form.Select
      name={name}
      isOpenByDefault={isOpenByDefault}
      label={label}
      variant={variant}
      autoFocus={isOpenByDefault}
      options={options}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      placeholder={placeholder}
      onClick={onClick}
      size={size}
      fullWidth
    />
  );
};

export default PlanSelect;
