import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useField } from 'formik';
import { Box, TextFieldProps, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import cx from 'classnames';
import IconFontAwesome from 'new/components/IconFontAwesome';

type Props = TextFieldProps & {
  name: string;
  label?: string;
  minutesStep?: number;
  isSmall?: boolean;
};

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',

  '&.hasError': {
    'input::placeholder, & .MuiInput-root, & .MuiInput-root svg': {
      color: theme.palette.error.main,
    },
  },

  '&.isSmall': {
    '.MuiOutlinedInput-root': {
      width: 90,
      padding: 0,
      borderRadius: 0,
    },

    fieldset: {
      width: 90,

      legend: { display: 'none' },
    },

    input: {
      width: 90,
      marginTop: -6,
      padding: '2px 8px',
      fontSize: '0.875rem',
    },

    button: {
      width: 0,
      padding: 0,

      svg: {
        position: 'absolute',
        top: 1,
        left: -90,
        width: 90,
        height: 24,
        opacity: 0,
      },
    },

    '.arrows': {
      position: 'absolute',
      top: 10,
      right: theme.spacing(2),

      div: {
        height: 11,

        svg: {
          position: 'relative',
          top: -8,
          height: 10,
          width: 12,
        },
      },
    },
  },
}));

function TimePicker({ name, label, minutesStep = 1, isSmall }: Props) {
  const [localValue, setLocalValue] = useState<Date | null>(null);
  const [field, meta, helpers] = useField(name);
  const hasError = !!meta.error && meta.touched;

  useEffect(() => {
    if (!localValue && field.value) {
      setLocalValue(field.value);
    }
  }, [field.value]);

  return (
    <StyledBox className={cx({ hasError, isSmall })}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <InputLabel htmlFor={name} error={hasError}>
          {label}
        </InputLabel>
        <MuiTimePicker
          value={field.value}
          minutesStep={minutesStep}
          onChange={(time) => {
            setLocalValue(time);
            helpers.setValue(time);
          }}
          onClose={() => {
            helpers.setValue(localValue);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant={isSmall ? 'outlined' : 'standard'}
              />
            );
          }}
        />
        <Box className="arrows">
          <IconFontAwesome
            name="faCaretUp"
            color="grey600"
            size="xs"
            onClick={() =>
              helpers.setValue(
                dayjs(field.value).add(minutesStep, 'minutes').toDate(),
              )
            }
          />
          <IconFontAwesome
            name="faCaretDown"
            color="grey600"
            size="xs"
            onClick={() =>
              helpers.setValue(
                dayjs(field.value).add(-minutesStep, 'minutes').toDate(),
              )
            }
          />
        </Box>
      </LocalizationProvider>
    </StyledBox>
  );
}

export default TimePicker;
