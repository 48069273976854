/*
Dentist Members Page Actions
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
// local
import {
  // fetch dentist
  FETCH_DENTIST_INFO_REQUEST,
  FETCH_DENTIST_INFO_SUCCESS,
  FETCH_DENTIST_INFO_ERROR,

  FETCH_DENTIST_INFO_FULL_REQUEST,
  FETCH_DENTIST_INFO_FULL_SUCCESS,
  FETCH_DENTIST_INFO_FULL_ERROR,

  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_ERROR,
  FETCH_MEMBERSCSV_REQUEST,

  // fetch patients
  FETCH_PATIENTS_REQUEST,
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENTS_ERROR,
  FETCH_PATIENTS_ALL_LOCATIONS_REQUEST,
  FETCH_PATIENTS_ALL_LOCATIONS_SUCCESS,

  // fetch dentist users
  FETCH_DENTIST_USERS_REQUEST,
  FETCH_DENTIST_USERS_SUCCESS,
  FETCH_DENTIST_USERS_ERROR,

  FETCH_RECURRING_DATE_REQUEST,
  FETCH_RECURRING_DATE_SUCCESS,
  FETCH_RECURRING_DATE_ERROR,

  // fetch reports
  FETCH_DENTIST_REPORTS_REQUEST,
  FETCH_DENTIST_REPORTS_SUCCESS,
  FETCH_DENTIST_REPORTS_ERROR,

  FETCH_DENTIST_WEB_REPORT_REQUEST,
  FETCH_DENTIST_WEB_REPORT_SUCCESS,
  FETCH_DENTIST_WEB_REPORT_ERROR,

  FETCH_DENTIST_PAYOUTS_REQUEST,
  FETCH_DENTIST_PAYOUTS_SUCCESS,
  FETCH_DENTIST_PAYOUTS_ERROR,

  FETCH_DENTIST_WEB_PAYOUT_REQUEST,
  FETCH_DENTIST_WEB_PAYOUT_SUCCESS,
  FETCH_DENTIST_WEB_PAYOUT_ERROR,

  // search / filter patients
  SEARCH_MEMBERS,
  SEARCH_MEMBERS_FINISHED,
  FILTER_MEMBERS,
  CLEAR_SEARCH_PATIENTS,

  // add / edit member
  SET_EDITING_MEMBER,
  CLEAR_EDITING_MEMBER,
  SUBMIT_MEMBER_FORM,
  ADD_MEMBER_SUCCESS,
  ACTIVATE_MEMBER_SUCCESS,
  EDIT_MEMBER_SUCCESS,
  ACTIVATE_CASH_MEMBER_REQUEST,
  SWITCH_OFFICES,

  // remove member
  REMOVE_MEMBER_REQUEST,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_RELOADING_MEMBER,

  // edit patient profile
  SET_EDITING_PATIENT_PROFILE,
  CLEAR_EDITING_PATIENT_PROFILE,
  SUBMIT_PATIENT_PROFILE_FORM,
  EDIT_PATIENT_PROFILE_SUCCESS,

  // edit dentist user
  SET_EDITING_DENTIST_USER,
  CLEAR_EDITING_DENTIST_USER,
  SUBMIT_DENTIST_USER_FORM,
  EDIT_DENTIST_USER_SUCCESS,
  DELETE_DENTIST_USER_FORM,

  // edit patient payment info
  SET_EDITING_PATIENT_PAYMENT,
  CLEAR_EDITING_PATIENT_PAYMENT,
  SUBMIT_PATIENT_PAYMENT_FORM,

  // toggle waive patient fees
  TOGGLE_WAIVE_PATIENT_FEES_REQUEST,
  TOGGLE_WAIVE_PATIENT_FEES_SUCCESS,

  // download report
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,


  // image upload
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,

  // signup
  DENTIST_SIGNUP_REQUEST,
  DENTIST_SIGNUP_SUCCESS,
  DENTIST_SIGNUP_ERROR,

  // edit security
  SET_EDITING_SECURITY,
  CLEAR_EDITING_SECURITY,
  SUBMIT_SECURITY_FORM,

  // edit security
  SET_EDITING_PAYMENT_PLANS,
  CLEAR_EDITING_PAYMENT_PLANS,
  SET_ELEVATE,
  CLEAR_ELEVATE,
  SUBMIT_PAYMENT_PLANS_FORM,

  // images deletion.
  DELETE_OFFICE_LOGO,
  SET_OFFICE_LOGO,
  DELETE_PRINT_LOGO,
  SET_PRINT_LOGO,
  DELETE_DENTIST_AVATAR,
  SET_DENTIST_AVATAR,
  DELETE_DENTIST_OFFICE_IMAGE,
  SET_DENTIST_OFFICE_IMAGE,

  // custom memberships
  ADD_CUSTOM_MEMBERSHIP,
  EDIT_CUSTOM_MEMBERSHIP,
  DELETE_CUSTOM_MEMBERSHIP,

  // invite
  INVITE_PATIENT,
  ADD_VARIDI,
  GARGLE_VARIDI,

  FETCH_OFFICES,
  FETCH_OFFICES_ERROR,
  FETCH_OFFICES_SUCCESS,

  TOGGLE_REFUNDING_MEMBER,
  TOGGLE_DISPUTES_MODAL,
  TOGGLE_DISPUTES_CONFIRM_MODAL,
  FETCH_DISPUTE_ID,
  FETCH_BY_DISPUTE_ID,
  INITIATE_DISPUTE_UPDATE,
  INITIATE_REFUNDING_MEMBER,
  INITIATE_DISPUTE_ACCEPT,
  TOGGLE_SAW_UPDATES_REQUEST,
  FETCH_DISPUTE_ID_SUCCESS,
  FETCH_DISPUTE_ID_FAILURE,
  SET_ADD_LOCATION,
  CLEAR_ADD_LOCATION,
  DENTIST_CODES_SIGNUP,
  DENTIST_DISCOUNT_SIGNUP,
  DENTIST_DISCOUNT_CODES_SIGNUP,
  SWITCH_TO_STEP,

  ACTIVE_CAMPAIGN_COMPLETE,

  ADD_DENTIST_PRICE_CODES,
  SUBMIT_CONTACT_US_MESSAGE_FORM,
  SUBMIT_CONTACT_US_ACCOUNT_FORM,

  CHARGE_FOR_MARKETING_MATERIALS,
  SEND_MM,
  REACTIVATE_USER,
  RESEND_PASSWORD_EMAIL,

  FETCH_DEFAULT_PAYMENT_REQUEST,
  FETCH_DEFAULT_PAYMENT_SUCCESS,

  RENEW_MEMBER_REQUEST,
  UPDATE_BUSINESS_CONNECT_TRAINING_ON,
  REMOVE_STANDARD,

  CHECK_EMAIL,
  CHECK_EMAIL_ERROR,
  CHECK_EMAIL_SUCCESS,


} from './constants';

/*
Fetch Dentist Info
------------------------------------------------------------
*/
export function fetchDentistInfo(dentistInfoId) {
  return {
    type: FETCH_DENTIST_INFO_REQUEST,
    dentistInfoId
  };
}

export function fetchDentistInfoSuccess(payload) {
  return {
    type: FETCH_DENTIST_INFO_SUCCESS,
    payload,
  };
}

export function fetchDentistInfoError(payload) {
  return {
    type: FETCH_DENTIST_INFO_ERROR,
    payload,
  };
}

export function fetchDentistInfoFull(dentistInfoId) {
  return {
    type: FETCH_DENTIST_INFO_FULL_REQUEST,
    dentistInfoId
  };
}

export function fetchDentistInfoFullSuccess(payload) {
  return {
    type: FETCH_DENTIST_INFO_FULL_SUCCESS,
    payload,
  };
}

export function fetchDentistInfoFullError(payload) {
  return {
    type: FETCH_DENTIST_INFO_FULL_ERROR,
    payload,
  };
}


export function fetchStats(dentistInfoId) {
  return {
    type: FETCH_STATS_REQUEST,
    dentistInfoId
  };
}

export function fetchStatsSuccess(payload) {
  return {
    type: FETCH_STATS_SUCCESS,
    payload,
  };
}

export function fetchStatsError(payload) {
  return {
    type: FETCH_STATS_ERROR,
    payload,
  };
}

export function fetchMembersCSV(dentistInfoId){
  return {
    type: FETCH_MEMBERSCSV_REQUEST,
    dentistInfoId
  }
}

/* Fetch Dentist Specialties
 * ------------------------------------------------------ */

/*
Fetch Patients
------------------------------------------------------------
*/
export function fetchPatients(dentistInfoId,initial,searchQuery,searchFilter,startIndex) {
  return {
    type: FETCH_PATIENTS_REQUEST,
    dentistInfoId,
    initial,
    searchQuery,
    searchFilter,
    startIndex
  };
}

export function fetchPatientsSuccess(payload,initial,noMoreResults) {
  return {
    type: FETCH_PATIENTS_SUCCESS,
    payload,
    initial,
    noMoreResults
  };
}

export function fetchPatientsError(payload) {
  return {
    type: FETCH_PATIENTS_ERROR,
    payload,
  };
}

export function fetchAllLocationsPatients(dentistInfoId) {
  return {
    type: FETCH_PATIENTS_ALL_LOCATIONS_REQUEST,
    dentistInfoId
  };
}

export function fetchPatientsAllLocationsSuccess(payload) {
  return {
    type: FETCH_PATIENTS_ALL_LOCATIONS_SUCCESS,
    payload,
  };
}

/*
Fetch Patients
------------------------------------------------------------
*/
export function fetchDentistUsers(dentistInfoId) {
  return {
    type: FETCH_DENTIST_USERS_REQUEST,
    dentistInfoId
  };
}

export function fetchDentistUsersSuccess(payload) {
  return {
    type: FETCH_DENTIST_USERS_SUCCESS,
    payload,
  };
}

export function fetchDentistUsersError(payload) {
  return {
    type: FETCH_DENTIST_USERS_ERROR,
    payload,
  };
}

/*
Fetch RECURRING DATE
------------------------------------------------------------
*/
export function fetchRecurringDate(patient) {
    return {
        type: FETCH_RECURRING_DATE_REQUEST,
        patient
    };
}

export function fetchRecurringDateSuccess(patient,response) {
    return {
        type: FETCH_RECURRING_DATE_SUCCESS,
        patient,
        response
    };
}

export function fetchRecurringDateError(payload) {
    return {
        type: FETCH_RECURRING_DATE_ERROR,
        payload,
    };
}
/* Fetch Dentist Reports
 * ------------------------------------------------------ */
export function fetchDentistReports(dentistInfoId) {
  return {
    type: FETCH_DENTIST_REPORTS_REQUEST,
    dentistInfoId
  };
}

export function fetchDentistReportsSuccess(payload) {
  return {
    type: FETCH_DENTIST_REPORTS_SUCCESS,
    payload,
  };
}

export function fetchDentistReportsError(payload) {
  return {
    type: FETCH_DENTIST_REPORTS_ERROR,
    payload,
  };
}

export function fetchDentistWebReport(dentistInfoId, year, month) {
  return {
    type: FETCH_DENTIST_WEB_REPORT_REQUEST,
    dentistInfoId, year, month,
  };
}

export function fetchDentistWebReportSuccess(payload) {
  return {
    type: FETCH_DENTIST_WEB_REPORT_SUCCESS,
    payload,
  };
}

export function fetchDentistWebReportError(payload) {
  return {
    type: FETCH_DENTIST_WEB_REPORT_ERROR,
    payload,
  };
}

/* Fetch Dentist Reports
 * ------------------------------------------------------ */
export function fetchDentistPayouts(dentistInfoId) {
  return {
    type: FETCH_DENTIST_PAYOUTS_REQUEST,
    dentistInfoId
  };
}

export function fetchDentistPayoutsSuccess(payload) {
  return {
    type: FETCH_DENTIST_PAYOUTS_SUCCESS,
    payload,
  };
}

export function fetchDentistPayoutsError(payload) {
  return {
    type: FETCH_DENTIST_PAYOUTS_ERROR,
    payload,
  };
}

export function fetchDentistWebPayout(dentistInfoId, payoutId) {
  return {
    type: FETCH_DENTIST_WEB_PAYOUT_REQUEST,
    dentistInfoId, payoutId,
  };
}

export function fetchDentistWebPayoutSuccess(payload) {
  return {
    type: FETCH_DENTIST_WEB_PAYOUT_SUCCESS,
    payload,
  };
}

export function fetchDentistWebPayoutError(payload) {
  return {
    type: FETCH_DENTIST_WEB_PAYOUT_ERROR,
    payload,
  };
}

/*
Search / Filter Patients
------------------------------------------------------------
*/
export function searchMembers(name) {
  return {
    type: SEARCH_MEMBERS,
    name,
  };
}

export function searchMembersFinished(name) {
  return {
    type: SEARCH_MEMBERS_FINISHED,
    name,
  };
}

export function filterMembers(status) {
  return {
    type: FILTER_MEMBERS,
    status,
  };
}
export function clearSearchPatients() {
  return {
    type: CLEAR_SEARCH_PATIENTS
  };
}

/*
Add / Edit Member
------------------------------------------------------------
*/
export function setEditingMember(patient, member, callback) {
  return {
    type: SET_EDITING_MEMBER,
    patient,
    member,
    callback
  };
}

export function clearEditingMember() {
  return {
    type: CLEAR_EDITING_MEMBER,
  };
}

export function submitMemberForm(accountHolder, member,userId) {
  if (member.subscriptions && member.subscriptions.status !== 'active'&&member.subscriptions.status!='active_cash'&&member.subscriptions.status!='active_transferred') {
    member.isEnrolling = true;
  } else {
    member.isEnrolling = false;
  }
  return {
    type: SUBMIT_MEMBER_FORM,
    accountHolder,
    member,
    userId
  };
}

export function switchOfficesSubmit(dentistInfoId, userId) {
  return {
    type: SWITCH_OFFICES,
    dentistInfoId,
    userId,
  };
}

export function setActivateMember(patient, payload) {
    return {
        type: ACTIVATE_MEMBER_SUCCESS,
        patient,
        payload,
    };
}

export function setAddedMember(accountHolder,member) {
    return {
        type: ADD_MEMBER_SUCCESS,
        accountHolder,
        member,
    };
}

export function setEditedMember(accountHolder,member, subscription) {
  return {
    type: EDIT_MEMBER_SUCCESS,
    accountHolder,
    member,
    subscription,
  };
}

/*
Remove Member
------------------------------------------------------------
*/
export function setRemovingMember(accountHolder, member, dentistInfoId,immediately,values) {
  return {
    type: REMOVE_MEMBER_REQUEST,
    accountHolder,
    member,
    dentistInfoId,
    immediately,
    values
  };
}

export function setRemovedMember(accountHolder, memberId, updatedSubscription) {
  return {
    type: REMOVE_MEMBER_SUCCESS,
    accountHolder,
    memberId,
    updatedSubscription,
  }
}

export function turnOffRemoving(){
  return {
    type: REMOVE_RELOADING_MEMBER
  }
}

/*
 Activate Cash Member
 ------------------------------------------------------------
 */
export function activateCashMember(patient, payload, dentistInfoId) {
    return {
        type: ACTIVATE_CASH_MEMBER_REQUEST,
        patient,
        payload,
        dentistInfoId
    };
}

/*
Edit Patient Profile
------------------------------------------------------------
*/
export function setEditingPatientProfile(patient) {
  return {
    type: SET_EDITING_PATIENT_PROFILE,
    patient,
  };
}

export function clearEditingPatientProfile() {
  return {
    type: CLEAR_EDITING_PATIENT_PROFILE,
  };
}

export function submitPatientProfileForm(payload) {
  return {
    type: SUBMIT_PATIENT_PROFILE_FORM,
    payload,
  };
}

export function setEditedPatientProfile(payload) {
  return {
    type: EDIT_PATIENT_PROFILE_SUCCESS,
    payload,
  };
}

/*
Edit DentistUsers
------------------------------------------------------------
*/
export function setEditingDentistUser(patient) {
  return {
    type: SET_EDITING_DENTIST_USER,
    patient,
  };
}

export function clearEditingDentistUser() {
  return {
    type: CLEAR_EDITING_DENTIST_USER,
  };
}

export function submitDentistUserForm(payload,dentistInfoId) {
  return {
    type: SUBMIT_DENTIST_USER_FORM,
    payload,
    dentistInfoId
  };
}

export function deleteDentistUser(payload) {
  return {
    type: DELETE_DENTIST_USER_FORM,
    payload,
  };
}


export function reactivateUser(payload,isDeleted) {
  return {
    type: REACTIVATE_USER,
    payload,
    isDeleted
  };
}


export function  resendPasswordEmail(payload){
  return {
    type: RESEND_PASSWORD_EMAIL,
    payload,
  };
}


export function setEditedDentistUser(payload) {
  return {
    type: EDIT_DENTIST_USER_SUCCESS,
    payload,
  };
}

/* Edit Payment Info
 * ------------------------------------------------------ */
export function setEditingPatientPayment(patient, paymentInfo) {
  return {
    type: SET_EDITING_PATIENT_PAYMENT,
    patient,
    paymentInfo,
  };
}

export function clearEditingPatientPayment() {
  return {
    type: CLEAR_EDITING_PATIENT_PAYMENT,
  };
}

export function submitPatientPaymentForm(patient, payload) {
  return {
    type: SUBMIT_PATIENT_PAYMENT_FORM,
    patient,
    payload,
  };
}

export function marketingMaterialsCharge(dentist, payload) {
  return {
    type: CHARGE_FOR_MARKETING_MATERIALS,
    dentist,
    payload,
  };
}

export function sendMMRequest(dentist, payload,businessConnect) {
  return {
    type: SEND_MM,
    dentist,
    payload,
    businessConnect
  };
}

// don't store payment info locally

/*
Toggle Waive Patient Fees
------------------------------------------------------------
*/
export function setTogglingWaivePatientFees(patient, payload) {
  return {
    type: TOGGLE_WAIVE_PATIENT_FEES_REQUEST,
    patient,
    payload,
  };
}

export function setToggledWaivePatientFees(patient, payload) {
  return {
    type: TOGGLE_WAIVE_PATIENT_FEES_SUCCESS,
    patient,
    payload,
  }
}


/* Download Report
 * ------------------------------------------------------ */
export function downloadReport(reportName, reportUrl) {
  return {
    type: DOWNLOAD_REPORT_REQUEST,
    reportName,
    reportUrl,
  };
}

export function downloadReportSuccess() {
  return {
    type: DOWNLOAD_REPORT_SUCCESS,
  };
}

export function downloadReportFailure(payload) {
  return {
    type: DOWNLOAD_REPORT_ERROR,
    payload,
  };
}

/*
Image Upload Actions
------------------------------------------------------
*/
export function uploadImageRequest(field, file) {
  return {
    type: UPLOAD_IMAGE_REQUEST,
    field,
    file,
  };
}

export function uploadImageSuccess(url) {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    url,
  };
}

/*
Signup Actions
------------------------------------------------------
*/
export function signupRequest(payload) {
  return {
    type: DENTIST_SIGNUP_REQUEST,
    payload
  };
}

export function signupSuccess(payload) {
  return {
    type: DENTIST_SIGNUP_SUCCESS,
    payload
  };
}

export function signupError(payload) {
  return {
    type: DENTIST_SIGNUP_ERROR,
    payload
  };
}

/*
Edit Security
------------------------------------------------------------
*/
export function setEditingSecurity(securityInfo) {
  return {
    type: SET_EDITING_SECURITY,
    securityInfo,
  };
}

export function clearEditingSecurity() {
  return {
    type: CLEAR_EDITING_SECURITY,
  };
}

export function submitSecurityForm(payload, user) {
  return {
    type: SUBMIT_SECURITY_FORM,
    payload,
    user,
  };
}

/*
Payment plans
------------------------------------------------------------
*/
export function setEditingPaymentPlans(dentistInfoId) {
  return {
    type: SET_EDITING_PAYMENT_PLANS,
    dentistInfoId,
  };
}

export function activateVaridi(dentistInfoId) {
  return {
    type: ACTIVATE_VARIDI,
    dentistInfoId,
  };
}

export function clearEditingPaymentPlans() {
  return {
    type: CLEAR_EDITING_PAYMENT_PLANS,
  };
}

export function setElevate(dentistInfoId) {
  return {
    type: SET_ELEVATE,
    dentistInfoId,
  };
}

export function activateElevate(dentistInfoId) {
  return {
    type: ACTIVATE_ELEVATE,
    dentistInfoId,
  };
}

export function clearElevate() {
  return {
    type: CLEAR_ELEVATE,
  };
}

export function submitPaymentPlansForm(payload, user) {
  return {
    type: SUBMIT_PAYMENT_PLANS_FORM,
    payload,
    user,
  };
}


export function setAddLocation(dentistInfoId) {
  return {
    type: SET_ADD_LOCATION,
    dentistInfoId,
  };
}

export function clearAddLocation() {
  return {
    type: CLEAR_ADD_LOCATION,
  };
}
// images.

export function deleteOfficeLogo (dentistInfoId) {
  return {
    type: DELETE_OFFICE_LOGO,
    dentistInfoId
  };
}

export function setOfficeLogo ( dentistInfoId,fileURL) {
  return {
    type: SET_OFFICE_LOGO,
    dentistInfoId,
    fileURL
  };
}

export function deletePrintLogo (dentistInfoId) {
  return {
    type: DELETE_PRINT_LOGO,
    dentistInfoId
  };
}

export function setPrintLogo ( dentistInfoId,fileURL) {
  return {
    type: SET_PRINT_LOGO,
    dentistInfoId,
    fileURL
  };
}

export function setDentistAvatar ( dentistInfoId,fileURL) {
  return {
    type: SET_DENTIST_AVATAR,
    dentistInfoId,
    fileURL
  };
}

export function deleteDentistAvatar (dentistInfoId) {
  return {
    type: DELETE_DENTIST_AVATAR,
    dentistInfoId
  };
}

export function deleteDentistOfficeImage ( dentistInfoId, dentistInfoPhotoId) {
  return {
    type: DELETE_DENTIST_OFFICE_IMAGE,
    dentistInfoId,
    dentistInfoPhotoId
  };
}

export function addCustomMembership (membershipPlan) {
  return {
    type: ADD_CUSTOM_MEMBERSHIP,
    membershipPlan
  };
}

export function editCustomMembership (customMemberships) {
  return {
    type: EDIT_CUSTOM_MEMBERSHIP,
    customMemberships
  };
}

export function deleteCustomMembership (customMemberships) {
  return {
    type: DELETE_CUSTOM_MEMBERSHIP,
    customMemberships
  };
}
// update user data at App level, see setUserData in `/app/containers/App/actions.js`

/*
Signup Actions
------------------------------------------------------
*/
export function invitePatient (payload) {
  return {
    type: INVITE_PATIENT,
    payload
  };
}

export function addVaridi (dentistInfoId) {
  return {
    type: ADD_VARIDI,
    dentistInfoId
  };
}

export function gargleVaridi (dentistInfoId) {
  return {
    type: GARGLE_VARIDI,
    dentistInfoId
  };
}

export function fetchOffices (dentistInfoId) {
  return {
    type: FETCH_OFFICES,
    dentistInfoId
  };
}

export function fetchOfficesSuccess (payload) {
  return {
    type: FETCH_OFFICES_SUCCESS,
    payload,
  };
}

export function fetchOfficesError (payload) {
  return {
    type: FETCH_OFFICES_ERROR,
    payload,
  };
}

export function toggleRefundingMember (value) {
  return {
    type: TOGGLE_REFUNDING_MEMBER,
    payload: value
  };
}

export function toggleDisputesModal (disputeId) {
  return {
    type: TOGGLE_DISPUTES_MODAL,
    payload: value
  };
}

export function toggleDisputeConfirmModal (disputeId) {
  return {
    type: TOGGLE_DISPUTES_CONFIRM_MODAL,
    payload: disputeId
  }
}

export function initiateRefundingMember (id, amount,reason) {
  return {
    type: INITIATE_REFUNDING_MEMBER,
    id,
    amount,
    reason
  };
}

export function initiateDisputesUpdate (disputeId, agreement, description, terms) {
  return {
    type: INITIATE_DISPUTE_UPDATE,
    disputeId,
    agreement,
    description,
    terms
  };
}

export function initiateDisputeAccept (disputeId, name) {
  return {
    type: INITIATE_DISPUTE_ACCEPT,
    disputeId
  };
}



export function sawUpdates(userId) {
  return {
    type: TOGGLE_SAW_UPDATES_REQUEST,
    userId,
  };
}

export function cancelDisputeResponse() {
  return {
    type: CLEAR_DISPUTE_RESPONSE,
  };
}

export function cancelDisputeAccept() {
  return {
    type: CLEAR_DISPUTE_ACCEPT,
  };
}

export function fetchDisputeId(patient) {
  return {
    type: FETCH_DISPUTE_ID,
    patient,
  };
}

export function fetchByDisputeId(disputeId) {
  return {
    type: FETCH_BY_DISPUTE_ID,
    disputeId,
  };
}

export function fetchDisputeIdSuccess(payload) {
  return {
    type: FETCH_DISPUTE_ID_SUCCESS,
    payload,
  };
}

export function fetchDisputeIdError(payload) {
  return {
    type: FETCH_DISPUTE_ID_FAILURE,
    payload,
  };
}

export function codesRequest(payload,show_toast) {
  return {
    type: DENTIST_CODES_SIGNUP,
    payload,
    show_toast
  };
}

export function discountRequest(payload) {
  return {
    type: DENTIST_DISCOUNT_SIGNUP,
    payload
  };
}

export function activeCampaignRequest(dentistInfoId,values) {
  return {
    type: ACTIVE_CAMPAIGN_COMPLETE,
    dentistInfoId,
    values
  };
}

export function discountCodesRequest(payload) {
  return {
    type: DENTIST_DISCOUNT_CODES_SIGNUP,
    payload
  };
}

export function switchToStep(dentistInfoId,step) {
  return {
    type: SWITCH_TO_STEP,
    dentistInfoId,
    step
  };
}

export function removeStandard(dentistInfoId) {
  return {
    type: REMOVE_STANDARD,
    dentistInfoId,
  };
}

export function setDentistOfficeImage ( dentistInfoId, fileURL,which) {
  return {
    type: SET_DENTIST_OFFICE_IMAGE,
    dentistInfoId,
    fileURL,
    which
  };
}

export function addDentistPriceCodes(payload){
    return {
      type: ADD_DENTIST_PRICE_CODES,
      payload
    };
  }

  export function submitContactUsForm (payload) {
    return {
      type: SUBMIT_CONTACT_US_MESSAGE_FORM,
      payload,
    };
  }
  export function submitContactUsAccountForm (payload) {
    return {
      type: SUBMIT_CONTACT_US_ACCOUNT_FORM,
      payload,
    };
  }

  export function fetchDefaultPayment(dentistInfoId,memberId) {
    return {
      type: FETCH_DEFAULT_PAYMENT_REQUEST,
      dentistInfoId,
      memberId
    };
  }

  export function fetchDefaultPaymentSuccess(payload) {
    return {
      type: FETCH_DEFAULT_PAYMENT_SUCCESS,
      payload
    };
  }

  export function renewMemberRequest(values){
    return {
      type: RENEW_MEMBER_REQUEST,
      values
    };
  }


export function setBusinessConnectTrainingOnAction (dentistInfoId,toggle) {
  return {
      type: UPDATE_BUSINESS_CONNECT_TRAINING_ON,
      dentistInfoId,
      toggle
  };
}

export function checkEmail(email){
  return {
    type: CHECK_EMAIL,
    email
  };
}

export function checkEmailError(){
  return {
    type: CHECK_EMAIL_ERROR,
  };
}

export function checkEmailSuccess(){
  return {
    type: CHECK_EMAIL_SUCCESS,
  };
}

