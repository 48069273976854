import { styled, Switch as MuiSwitch } from '@mui/material';

const Switch = styled(MuiSwitch)(({ theme }) => ({
  padding: theme.spacing(1),

  '& .MuiSwitch-track': {
    borderRadius: 11,

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },

  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    backgroundColor: '#fff',
    width: 16,
    height: 16,
    margin: 2,
  },

  '& .Mui-checked': {
    transform: 'translateX(16px)',
    color: '#fff',

    '+ .MuiSwitch-track': {
      opacity: '1 !important',
    },
  },
}));

export default Switch;
