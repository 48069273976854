import { Theme } from '@mui/material/styles';
import {
  Member,
  MemberDetailsContextType,
  MemberStatus,
  MemberTabType,
  SimplifiedMemberStatus,
  Subscription,
} from 'new/modules/MembersPage/types';
import { getIsMonthlyPlan } from 'new/components/PlanSelect';
import { Plan } from '../MembershipPlansManage/types';
import { SubscriptionContextType } from 'new/modules/MembersPage/types';
import { createContext } from 'react';

export const transformTableRowToTab = (row: Member) =>
  ({
    id: row.id,
    isAccountOwner: !row.addedBy,
    status: row.subscriptions
      ? getSimplifiedMemberStatus(
          row.subscriptions.map((subscription) => subscription.status),
        )
      : undefined,
    name: `${row.firstName} ${row.lastName}`,
    birthDate: row.birthDate,
  } as MemberTabType);

export const getStatusBackgroundColor = (
  theme: Theme,
  status?: SimplifiedMemberStatus,
) => {
  const statusMap: Record<SimplifiedMemberStatus, string> = {
    active: theme.palette.success.main,
    canceled: theme.palette.error.main,
    other: theme.palette.grey[600],
    late: theme.palette.warning.main,
  };

  return (status && statusMap[status]) || theme.palette.secondary.dark;
};

export const getIsCashPayment = (brand?: string) =>
  ['cash'].includes(brand?.toLowerCase() || '');

export const getPlanNameWithSuffix = (
  planName?: string,
  type?: Plan['type'],
) => {
  const isMonthlyPlan = getIsMonthlyPlan(type);

  return `${planName} (${isMonthlyPlan ? 'M' : 'A'})`;
};

export const getIsActiveStatus = (status = '') =>
  ['active', 'active_cash', 'active_transferred'].includes(status);

// INFO: this was temporarly removed, but we might need this later
// export function addRealMonth(d: moment.Moment, dayOfCharge: number) {
//   // where d is a moment and dayOfCharge is an integer
//   let nextCharge = d;
//   //If leap year, next month is february and charge date is 29/30/31, make it the first day of next month
//   const year = parseInt(d.format('YYYY'));
//   const month = parseInt(d.format('M'));
//   const day = parseInt(d.format('D'));
//   if (
//     year % 4 == 0 &&
//     (day == 29 || day == 30 || day == 31) &&
//     month == 1 &&
//     (dayOfCharge == 29 || dayOfCharge == 30 || dayOfCharge == 31)
//   ) {
//     nextCharge = d.add(dayOfCharge, 'day').endOf('month');
//     //If not leap year, next month is february and charge date is 28/29/30/31, make it the first day of next month
//   } else if (
//     year % 4 != 0 &&
//     (day == 28 || day == 29 || day == 30 || day == 31) &&
//     month == 1 &&
//     (dayOfCharge == 28 ||
//       dayOfCharge == 29 ||
//       dayOfCharge == 30 ||
//       dayOfCharge == 31)
//   ) {
//     nextCharge = d.add(dayOfCharge, 'day').endOf('month');
//     //if its the last day of february and leap year and charge day is 29 or 30 or 31st, charge on right day next month
//   } else if (
//     year % 4 == 0 &&
//     (dayOfCharge == 29 || dayOfCharge == 30 || dayOfCharge == 31) &&
//     month == 2
//   ) {
//     nextCharge = d.add(dayOfCharge, 'day');
//     // if not those but leap year, add 29 days
//   } else if (month == 2 && year % 4 == 0) {
//     nextCharge = d.add(29, 'day');
//     // if not leap year, add 28 days
//   } else if (month == 2 && year % 4 != 0) {
//     nextCharge = d.add(28, 'day');
//     // add 30 for several months, but only if not usually on last day of month
//   } else if (
//     (month == 4 || month == 6 || month == 9 || month == 11) &&
//     dayOfCharge != 31
//   ) {
//     nextCharge = d.add(30, 'day');
//     // add 30 for 31 day hitter if before a 30 day month
//   } else if (
//     (month == 3 || month == 5 || month == 8 || month == 10) &&
//     dayOfCharge == 31
//   ) {
//     nextCharge = d.add(30, 'day');
//   } else {
//     nextCharge = d.add(31, 'day');
//   }
//   return nextCharge;
// }

export const MemberDetailsContext = createContext<
  MemberDetailsContextType | undefined
>(undefined);
export const SubscriptionContext = createContext<
  SubscriptionContextType | undefined
>(undefined);

export const getSubscriptionStatus = (status: MemberStatus) => {
  return {
    isCancellationPending: status === 'cancellation_requested',
    isPermanentlyDisabled: status === 'permanently_disabled',
    isLatePayment: status === 'past_due',
    isInActive: status === 'inactive',
    isDelinquent: ['delinquent', 'delinquent_gpp'].includes(status),
    isGPPDelinquent: status === 'delinquent_gpp',
    isActive: [
      'active',
      'active_cash',
      'active_transferred',
      'past_due',
    ].includes(status),
    isCancelable: ![
      'canceled',
      'pending_cash',
      'delinquent',
      'inactive_transferred',
    ].includes(status),
    isInActiveExtended: [
      'canceled',
      'delinquent',
      'cancellation_requested',
      'pending_cash',
      'inactive_transferred',
      'inactive',
      'permanently_disabled',
    ].includes(status),
  };
};

export const getMemberStatus = (subscriptions: Subscription[]) => {
  return subscriptions.reduce(
    (acc, subscription) => {
      const {
        isActive,
        isDelinquent,
        isLatePayment,
        isInActiveExtended,
        isCancellationPending,
      } = getSubscriptionStatus(subscription.managePlan.status);
      return {
        hasActive: acc.hasActive || isActive,
        hasDelinquent: acc.hasDelinquent || isDelinquent,
        hasLatePayment: acc.hasLatePayment || isLatePayment,
        isInActiveExtended: acc.isInActiveExtended && isInActiveExtended,
        isCancellationPending:
          acc.isCancellationPending || isCancellationPending,
      };
    },
    {
      hasActive: false,
      hasDelinquent: false,
      hasLatePayment: false,
      isInActiveExtended: false,
      isCancellationPending: false,
    },
  );
};

export const getSimplifiedMemberStatus = (
  statuses: MemberStatus[],
): SimplifiedMemberStatus => {
  if (statuses.includes('past_due')) return 'late';
  if (
    statuses.some((status) =>
      ['active', 'active_cash', 'active_transferred'].includes(status),
    )
  )
    return 'active';
  if (
    statuses.some((status) =>
      [
        'canceled',
        'delinquent',
        'cancellation_requested',
        'delinquent_gpp',
      ].includes(status),
    )
  )
    return 'canceled';
  return 'other';
};

export const getDiscountedPrice = (price: number, discountPercent: number) =>
  (price / 100) * (100 - discountPercent);
