import { NamedRoute } from '../../routing/types';

export type PatientDetailsDashboardRouteName = 'PatientDetailsDashboardPage';

export const patientDetailsDashboardRoutes: NamedRoute[] = [
  {
    name: 'PatientDetailsDashboardPage',
    path: '/patient-details/dashboard',
  },
];
