import React, { ChangeEvent, Ref, useRef } from 'react';
import { useField } from 'formik';
import {
  Box,
  TextFieldProps,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';
import useFieldAutofocus from 'new/hooks/useFieldAutofocus';
import StyledCheckbox from 'new/components/Checkbox';
import useI18nError from 'new/i18n/useI18nError';

type Props = TextFieldProps & {
  name: string;
  label: React.ReactElement | string;
  id?: string;
  className?: string;
  showErrorIfUntouched?: boolean;
  sx?: SxProps<Theme>;
};

type StyledBoxProps = {
  hasError?: boolean;
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<StyledBoxProps>(({ theme }) => ({
  padding: '0.5rem',

  '& .MuiTypography-root': {
    marginTop: 9,
  },
  '& .MuiCheckbox-root': {
    paddingBottom: 0,
    color: theme.palette.primary.main,
  },
}));

function Checkbox({
  id,
  name,
  label,
  disabled,
  autoFocus,
  onChange: onChangeProp,
  className,
  sx,
}: Props) {
  const inputRef = useRef<HTMLInputElement>();
  const [{ onChange, ...field }, meta, helpers] = useField<boolean>(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = !!meta.error && meta.touched;

  useFieldAutofocus(inputRef, autoFocus);

  return (
    <StyledBox hasError={hasError} className={className} sx={sx}>
      <FormControlLabel
        id={id || field.name}
        disabled={disabled}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            const newEvent = {
              ...event,
              target: { ...event.target, checked: !field.value },
            } as unknown as ChangeEvent<HTMLInputElement>;

            onChangeProp?.(newEvent);
            event.preventDefault();
            event.stopPropagation();
            helpers.setValue(!field.value);
          }
        }}
        control={
          <StyledCheckbox
            {...field}
            checked={field.value}
            inputRef={inputRef as Ref<HTMLInputElement>}
            onChange={(e) => {
              onChangeProp?.(e);
              return onChange(e);
            }}
            disabled={disabled}
            color="primary"
          />
        }
        label={label}
      />
      <FormHelperText error={hasError}>
        {hasError && errorMessage}
      </FormHelperText>
    </StyledBox>
  );
}

export default Checkbox;
