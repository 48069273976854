import React from 'react';
import { useField } from 'formik';
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers';
import { TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { COMMON_DATE_FORMAT } from 'new/constants/date';
import useI18nError from 'new/i18n/useI18nError';

type Props = Omit<TextFieldProps, 'onChange' | 'onError'> & {
  name: string;
  label?: string;
  datepickerProps?: Omit<
    DatePickerProps<Date, Date>,
    'value' | 'onChange' | 'renderInput'
  >;
  ref?: React.Ref<HTMLDivElement>;
};

function DatePicker({ name, label, datepickerProps, ref, ...props }: Props) {
  const [field, meta, helpers] = useField(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = !!meta.error && meta.touched;

  return (
    <DesktopDatePicker
      id={props.id || field.name}
      label={label}
      value={field.value}
      onBlur={field.onBlur}
      onChange={(date) => {
        helpers.setValue(date);
        helpers.setTouched(true);
      }}
      inputFormat={COMMON_DATE_FORMAT}
      disabled={props.disabled}
      ref={ref}
      {...props}
      {...datepickerProps}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          InputProps={{ ...params.InputProps, ...props.InputProps }}
          error={hasError}
          helperText={(hasError && errorMessage) || props.helperText}
          variant="standard"
        />
      )}
    />
  );
}

export default DatePicker;
