import React, { ReactNode, ReactText, useRef } from 'react';
import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  RadioGroupProps,
  Box,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { RadioPropsSizeOverrides } from '@mui/material/Radio/Radio';
import MuiRadio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import useFieldAutofocus from 'new/hooks/useFieldAutofocus';
import useI18nError from 'new/i18n/useI18nError';

type Props = RadioGroupProps & {
  name: string;
  label?: string | React.ReactElement;
  options: RadioOption[];
  defaultValue?: ReactText;
  size?: OverridableStringUnion<'small' | 'medium', RadioPropsSizeOverrides>;
  disabled?: boolean;
};

export type RadioOption = {
  value: ReactText | boolean;
  label: ReactNode;
};

type StyledWrapperProps = {
  hasError?: boolean;
  isRow?: boolean;
};

const StyledWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<StyledWrapperProps>(({ hasError, isRow, theme }) => ({
  position: 'relative',
  '& .MuiFormControl-root': {
    flexDirection: isRow && 'row',
    flexWrap: isRow ? 'wrap' : 'nowrap',
    marginTop: 32,
    marginBottom: hasError ? 24 : 0,
  },
  '& .MuiFormLabel-root': {
    top: isRow && 10,
    marginRight: 80,
    marginBottom: 8,
  },
  '& .MuiFormControlLabel-root': {
    marginTop: 0,
    marginRight: 50,
    '& .MuiSvgIcon-root': {
      color: hasError && theme.palette.error.main,
    },
    '& .MuiTypography-root': {
      color: hasError && theme.palette.error.main,
    },
    '& .MuiRadio-root': {
      padding: !isRow && '0 4px 4px 16px',
    },
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: isRow ? 12 : -8,
    left: 11,
  },

  '.MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

function Radio({
  name,
  label,
  options = [],
  defaultValue,
  size,
  disabled,
  ...props
}: Props) {
  const [field, meta] = useField(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = meta.touched && !!meta.error;
  const inputRef = useRef<HTMLInputElement>();
  useFieldAutofocus(inputRef, true);

  return (
    <StyledWrapper hasError={hasError} isRow={props.row}>
      <FormControl component="div" error={hasError} fullWidth>
        <FormLabel component="div">{label}</FormLabel>
        <RadioGroup
          {...props}
          defaultValue={defaultValue}
          name={name}
          ref={inputRef}
        >
          {options.map(({ label, value }) => (
            <FormControlLabel
              key={value.toString()}
              {...field}
              control={<MuiRadio size={size} />}
              value={value}
              label={label as string}
              checked={value === field.value}
              disabled={disabled == true}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormHelperText error={hasError} id={`${name}-helper-text`}>
        {hasError && errorMessage}
      </FormHelperText>
    </StyledWrapper>
  );
}

export default Radio;
