import React, { useCallback, useState, ReactText } from 'react';
import { useField } from 'formik';
import {
  InputAdornment,
  IconButton,
  TextFieldProps,
  Tooltip,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MaskedInput, { Mask } from 'react-text-mask';
import useI18nError from 'new/i18n/useI18nError';

type Props = TextFieldProps & {
  name: string;
  mask: Mask;
  guide?: boolean;
  initialIsVisible?: boolean;
  customToggleLabel?: string;
};

const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
  e.preventDefault();
};

function InputPasswordWithMask({
  name,
  mask,
  guide = false,
  initialIsVisible = false,
  customToggleLabel,
  variant = 'standard',
  ...props
}: Props) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const [field, meta] = useField<ReactText>(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = meta.touched && !!meta.error;

  const toggleVisibility = useCallback(
    () => setIsVisible((isVisible) => !isVisible),
    [],
  );

  return (
    <MaskedInput
      mask={mask}
      guide={guide}
      {...field}
      render={(ref, inputProps) => (
        <TextField
          id={props.id || field.name}
          {...props}
          {...inputProps}
          type={isVisible ? 'text' : 'password'}
          inputRef={ref}
          error={hasError}
          helperText={(hasError && errorMessage) || props.helperText}
          variant={variant}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={`${isVisible ? 'Hide' : 'Show'} ${
                    customToggleLabel || 'password'
                  }`}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleVisibility}
                    onMouseDown={handleMouseDownPassword}
                    tabIndex={-1}
                  >
                    {isVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

export { InputPasswordWithMask };
