import { NamedRoute } from '../../routing/types';

export type PatientDetailsPaymentHistoryRouteName =
  'PatientDetailsPaymentHistoryPage';

export const patientDetailsPaymentHistoryRoutes: NamedRoute[] = [
  {
    name: 'PatientDetailsPaymentHistoryPage',
    path: '/patient-details/payment-history',
  },
];
