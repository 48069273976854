import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { TENANT } from 'new/tenants';

type Props = {
  children: string;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontSize: ' 0.75rem',
  fontFamily: TENANT.theme.modal.fontFamily,
  marginTop: 9,
  lineHeight: '1rem',
  color: theme.palette.error.main,
}));

export const InputSuberror: React.FC<Props> = ({ children }) => (
  <StyledTypography>{children}</StyledTypography>
);
