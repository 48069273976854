import React, { MutableRefObject, ReactElement, RefObject } from 'react';
import {
  Form as FormikForm,
  Formik,
  FormikConfig,
  FormikProps,
  FormikValues,
} from 'formik';
import { SxProps } from '@mui/system/styleFunctionSx';
import { styled, Theme } from '@mui/material/styles';
import { Input } from './Input';
import { InputPassword } from './InputPassword';
import { InputPasswordWithMask } from './InputPasswordWithMask';
import Select from './Select';
import Radio from './Radio';
import Checkbox from './Checkbox';
import InputWithMask from './InputWithMask';
import AddressAutocomplete from 'new/components/Form/AddressAutocomplete';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import ConfirmCodeInput from './ConfirmCodeInput';
import RadioWithThumbnail from './RadioWithThumbnail';
import Toggle from './Toggle';
import Footer from './Footer';
import Switch from './Switch';
import Autocomplete from './Autocomplete';
import InputUCR from './InputUCR/InputUCR';
import ConfirmName from './ConfirmName';

type StyledFormProps = { useFormLayout?: boolean };

type Components = {
  Input: typeof Input;
  Select: typeof Select;
  InputWithMask: typeof InputWithMask;
  AddressAutoComplete: typeof AddressAutocomplete;
  ConfirmCodeInput: typeof ConfirmCodeInput;
  RadioWithThumbnail: typeof RadioWithThumbnail;
  Toggle: typeof Toggle;
  Footer: typeof Footer;
};

type FormProps<T> = StyledFormProps & {
  className?: string;
  formikPropsRef?: MutableRefObject<FormikProps<T> | null>;
  id?: string;
  formRef?: RefObject<HTMLFormElement>;
};

type Props<T> = FormProps<T> &
  Omit<FormikConfig<T>, 'onSubmit'> &
  Partial<Pick<FormikConfig<T>, 'onSubmit'>> & { sx?: SxProps<Theme> };

const StyledForm = styled(FormikForm, {
  shouldForwardProp: (prop) => prop !== 'useFormLayout',
})<StyledFormProps>(
  (props) =>
    props.useFormLayout && {
      '& .MuiTextField-root, &. MuiAutocomplete-root': {
        margin: 10,
        marginBottom: 20,
      },
      '& .MuiFormControl-root': { margin: 10 },
      '& .MuiButton-root': { margin: 10, minWidth: '10ch', height: '50px' },
      height: '100%',
    },
);

function Form<T extends FormikValues>({
  className,
  children,
  formikPropsRef,
  id,
  onSubmit = () => undefined,
  useFormLayout,
  sx,
  formRef,
  ...formikProps
}: Props<T>): ReactElement<Props<T> & Components> {
  return (
    <Formik {...formikProps} innerRef={formikPropsRef} onSubmit={onSubmit}>
      <StyledForm
        className={className}
        id={id}
        useFormLayout={useFormLayout}
        sx={sx}
        ref={formRef}
      >
        {children}
      </StyledForm>
    </Formik>
  );
}

// Fields
Form.Input = Input;
Form.InputWithMask = InputWithMask;
Form.InputPassword = InputPassword;
Form.InputPasswordWithMask = InputPasswordWithMask;
Form.InputUCR = InputUCR;
Form.Select = Select;
Form.Radio = Radio;
Form.Checkbox = Checkbox;
Form.AddressAutocomplete = AddressAutocomplete;
Form.DatePicker = DatePicker;
Form.TimePicker = TimePicker;
Form.ConfirmCodeInput = ConfirmCodeInput;
Form.RadioWithThumbnail = RadioWithThumbnail;
Form.Footer = Footer;
Form.Toggle = Toggle;
Form.Switch = Switch;
Form.Autocomplete = Autocomplete;
Form.ConfirmName = ConfirmName;

export default Form;
