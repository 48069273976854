import { Box, InputAdornment, SxProps, Theme, Tooltip } from '@mui/material';
import Form from '../Form';
import { MATCHED_CODES_VALUES, UCRCode } from '.';

type Props = {
  code: UCRCode;
  namePrefix?: string;
  labelPrefix?: string;
  disabled?: boolean;
  tooltipPlacement?:
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start';
  sx?: SxProps<Theme>;
};

const InputUCR: React.FC<Props> = ({
  code,
  namePrefix = '',
  labelPrefix = '',
  disabled = false,
  tooltipPlacement = 'top',
  sx,
}) => {
  const item = MATCHED_CODES_VALUES.find((val) => val.value === code);
  if (!item) return null;
  return (
    <Tooltip title={item.label} placement={tooltipPlacement}>
      <Box component="span">
        <Form.Input
          name={`${namePrefix}${code}`}
          label={labelPrefix.toUpperCase() + code}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          disabled={disabled}
          type="number"
          sx={sx}
        />
      </Box>
    </Tooltip>
  );
};

export default InputUCR;
