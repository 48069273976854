import React, { ReactText, useMemo } from 'react';
import { useField } from 'formik';
import { InputAdornment, TextField } from '@mui/material';
import MaskedInput, { Mask } from 'react-text-mask';
import CircularProgress from '@mui/material/CircularProgress';
import { InputFieldProps } from './Input';
import useI18nError from 'new/i18n/useI18nError';

type Props = InputFieldProps & {
  mask: Mask;
  guide?: boolean;
};

const InputWithMask: React.FC<Props> = ({
  name,
  mask,
  guide = false,
  isLoading,
  variant = 'standard',
  ...props
}) => {
  const [field, meta] = useField<ReactText>(name);
  const errorMessage = useI18nError(meta.error);

  const hasError = !!meta.error && meta.touched;

  const endAdornment = useMemo(() => {
    if (isLoading) {
      return (
        <InputAdornment position="end">
          <CircularProgress color="secondary" size={15} />
        </InputAdornment>
      );
    }

    return props.InputProps?.endAdornment;
  }, [isLoading, props.InputProps?.endAdornment]);

  return (
    <MaskedInput
      mask={mask}
      guide={guide}
      {...field}
      render={(ref, inputProps) => (
        <TextField
          {...props}
          error={hasError}
          helperText={(hasError && errorMessage) || props.helperText}
          variant={variant}
          inputRef={ref}
          onBlur={field.onBlur}
          InputLabelProps={{ shrink: !!field.value }}
          InputProps={{
            ...props.InputProps,
            ...inputProps,
            endAdornment,
          }}
        />
      )}
    />
  );
};

export default InputWithMask;
