import React, { useCallback, useEffect, useMemo } from 'react';
import { useField } from 'formik';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AutocompleteValue } from '@mui/material';

import { DEBOUNCE_TYPING_TIMEOUT } from '../../utils/constants';
import { InputFieldProps } from './Input';
import { OnAddressSelect } from 'new/modules/Setup/types';
import useI18nError from 'new/i18n/useI18nError';

export const initAddressField: AddressField = {
  inputValue: '',
  placeMeta: null,
};

let autocompleteService: google.maps.places.AutocompleteService;

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export type AddressField = {
  inputValue: string;
  placeMeta: AutocompletePrediction | null;
};

type Props = InputFieldProps & {
  name: string;
  onAddressSelect: OnAddressSelect;
  types?:
    | 'geocode'
    | 'postal_code'
    | 'address'
    | 'establishment'
    | 'regions'
    | 'cities';
};

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: AutocompletePrediction,
) => {
  const matches = option.structured_formatting.main_text_matched_substrings;
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length]),
  );

  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item>
          <Box
            component={LocationOnIcon}
            sx={{ color: 'text.secondary', mr: 2 }}
          />
        </Grid>
        <Grid item xs>
          {parts.map((part) => (
            <span
              key={part.text}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="text.secondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};

const AddressAutocomplete: React.FC<Props> = ({
  name,
  onAddressSelect,
  inputProps,
  types = 'address',
  ...props
}) => {
  const [{ value, ...field }, meta, helpers] = useField<AddressField>(name);
  const [options, setOptions] = React.useState<AutocompletePrediction[]>([]);
  const errorMessage = useI18nError(meta.error);
  const hasError = !!meta.error && meta.touched;

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: AutocompletePrediction[]) => void,
        ) => {
          autocompleteService.getPlacePredictions(
            {
              ...request,
              types: [types],
              componentRestrictions: { country: 'us' },
            },
            (res) => {
              callback(res);
            },
          );
        },
        DEBOUNCE_TYPING_TIMEOUT,
      ),
    [],
  );

  useEffect(() => {
    const { inputValue } = value ?? {};
    let active = true;

    if (!autocompleteService && window.google) {
      autocompleteService = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value.placeMeta ? [value.placeMeta] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: AutocompletePrediction[] = [];

        if (value?.placeMeta) {
          newOptions = [value.placeMeta];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, fetch]);

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent,
      value: AutocompleteValue<unknown, false, false, false>,
    ) => {
      const newValue = value as AutocompletePrediction | null;

      const addressField: AddressField = {
        inputValue: newValue?.structured_formatting.main_text || '',
        placeMeta: newValue,
      };

      setOptions((options) => (newValue ? [newValue, ...options] : options));
      helpers.setValue(addressField);
      onAddressSelect?.(newValue?.place_id);
    },
    [onAddressSelect],
  );

  return (
    <Autocomplete
      id={props.id || field.name}
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option
          : option.structured_formatting.main_text
      }
      {...field}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value?.placeMeta}
      onChange={handleChange}
      inputValue={value?.inputValue}
      onInputChange={(event, newInputValue) => {
        helpers.setValue({
          ...value,
          inputValue: newInputValue,
        });
      }}
      size={props.size}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...props}
          {...params}
          error={hasError}
          helperText={(hasError && errorMessage) || props.helperText}
          fullWidth
          inputProps={{
            ...params.inputProps,
            ...inputProps,
          }}
        />
      )}
      renderOption={renderOption}
      clearOnBlur={false}
    />
  );
};

export default AddressAutocomplete;
