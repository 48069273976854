import axios from 'axios';
import { getItem } from 'utils/localStorage';
import { errorHandler, errorHandlerNoToast } from './errorHandler';
import { successHandler, successHandlerNoToast } from './successHandler';

export const baseURL = '/api/v1/';

export const api = axios.create({
  baseURL,
  timeout: 0,
});

api.interceptors.request.use(
  // @ts-ignore
  (config) => {
    const authToken = getItem('auth_token');

    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    if (authToken) config.headers['Authorization'] = `JWT ${authToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(successHandler, errorHandler);

export const apiNoResponseMsg = axios.create({
  baseURL,
  timeout: 0,
});

apiNoResponseMsg.interceptors.request.use(
  // @ts-ignore
  (config) => {
    const authToken = getItem('auth_token');

    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    if (authToken) config.headers['Authorization'] = `JWT ${authToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiNoResponseMsg.interceptors.response.use(
  successHandlerNoToast,
  errorHandlerNoToast,
);
