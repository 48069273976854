import { MutableRefObject, useEffect } from 'react';

const useFieldAutofocus = (
  inputRef: MutableRefObject<HTMLInputElement | undefined>,
  autoFocus?: boolean,
) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (autoFocus) {
      timeout = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [autoFocus]);
};

export default useFieldAutofocus;
