import { Box, BoxProps } from '@mui/material';
import { Form } from '.';
import { PropsWithChildren } from 'react';

type Props = {
  textProps?: BoxProps;
  inputStyle?: React.CSSProperties;
};

const ConfirmName: React.FC<PropsWithChildren<Props>> = ({
  children,
  textProps = {},
  inputStyle = {},
}) => {
  return (
    <>
      <Box
        mb={2}
        textAlign="center"
        fontStyle="italic"
        fontSize="0.875rem"
        sx={(theme) => ({ color: theme.palette.grey[800] })}
        {...textProps}
      >
        {children}
      </Box>
      <Form.Input
        name="confirmName"
        variant="outlined"
        size="small"
        style={{ width: '20rem', ...inputStyle }}
      />
    </>
  );
};

export default ConfirmName;
