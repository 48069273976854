import {
  Box,
  FormHelperText,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React from 'react';
import { useField } from 'formik';
import { styled } from '@mui/material/styles';
import { SelectOption } from './Select';

export const ToggleWrapper = styled(Box)(({ theme }) => ({
  '.MuiToggleButtonGroup-root': {
    margin: `0 ${theme.spacing(4)}`,
  },

  '.MuiFormHelperText-root': {
    marginTop: 0,
    display: 'flex',
  },

  '.toggleButton': {
    width: '100%',
    height: 30,
    padding: theme.spacing(1),
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '0.75rem',
    textTransform: 'none',
    border: '0.5px solid #C4C4C4',
    borderLeft: '1px solid #C4C4C4 !important',

    '&[aria-pressed="false"]': {
      color: '#A3A3A3',
      backgroundColor: '#F7F7F7',
    },

    '&[aria-pressed="true"]': {
      backgroundColor: '#fff',
    },
  },
}));

type Props = {
  name: string;
  options: SelectOption[];
  label?: string;
  selected?: string;
  disabled?: boolean;
};

const Toggle: React.FC<Props> = ({
  name,
  label = '',
  options,
  selected,
  disabled = false,
}) => {
  const [, meta, helpers] = useField<string>(name);
  const hasError = !!meta.error && meta.touched;

  return (
    <ToggleWrapper>
      <InputLabel sx={{ textAlign: 'left', color: '#000' }} htmlFor={name}>
        {label}
      </InputLabel>
      <ToggleButtonGroup
        id={name}
        value={selected}
        exclusive={true}
        onChange={(event: unknown) => {
          const { value } = (event as React.ChangeEvent<HTMLInputElement>)
            .target;
          helpers.setValue(value);
        }}
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
        disabled={disabled}
      >
        {options.map(({ label, value }) => (
          <ToggleButton key={value} value={value} className="toggleButton">
            {label || value}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <FormHelperText error={hasError}>{hasError && meta.error}</FormHelperText>
    </ToggleWrapper>
  );
};

export default Toggle;
